import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicPageModule } from '../../modules/basic-page/basic-page.module';

@Component({
	selector: 'app-cheap-talk',
	templateUrl: './cheap-talk.component.html',
	styleUrls: ['./cheap-talk.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, BasicPageModule],
	standalone: true,
})
export class CheapTalkComponent {}
