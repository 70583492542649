import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SoundOnModule } from '../../modules/sound-on/sound-on.module';

@Component({
	selector: 'app-sound-on-page',
	templateUrl: './sound-on-page.component.html',
	styleUrls: ['./sound-on-page.component.scss'],
	standalone: true,
	imports: [CommonModule, SoundOnModule],
})
export class SoundOnPageComponent implements OnInit {
	constructor(private readonly router: Router) {}

	ngOnInit(): void {}

	onNext(): void {
		this.router.navigate(['/2years'], { skipLocationChange: true });
	}
}
