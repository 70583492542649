<div (appCapsLock)="onCapsLock($event)">
    <form>
        <label appTypography textStyle="body-small">passkey</label>
        <input class="private-page__input" autocomplete="current-password" [formControl]="password"
               (keyup.enter)="onSubmit()" (input)="onChange()"/>
    </form>
    <div class="private-page__password-info">
        <div *ngIf="!isCorrect" class="private-page__password-info-item" appTypography
             textStyle="body-small">
            <i class="mi-close"></i>
            invalid password
        </div>
        <div *ngIf="password.errors?.['minlength']" class="private-page__password-info-item"
             appTypography textStyle="body-small">
            <i class="mi-close"></i>
            at least {{ minLength }} characters
        </div>
        <div *ngIf="password.errors?.['strong']" class="private-page__password-info-item"
             appTypography textStyle="body-small">
            <i class="mi-close"></i>
            at least 1 upper case
        </div>
        <div *ngIf="capsOn" class="private-page__password-info-item" appTypography
             textStyle="body-small">
            <i class="mi-warning"></i>
            caps lock is on
        </div>
    </div>

    <div class="private-page__suggestions">
        <div class="private-page__suggestions__title"
             appTypography
             textStyle="body-small">
            suggestions
        </div>
        <div class="private-page__suggestions__list">
            <button appBtn buttonType="link" (click)="submitSuggestion('Decisions')">decisions
                <i class="mi-arrow-right"></i>
            </button>
            <button appBtn buttonType="link" (click)="submitSuggestion('CheapTalk')">talk is
                <i>not</i> cheap
                <i class="mi-arrow-right"></i>
            </button>
            <button appBtn buttonType="link" (click)="submitSuggestion('Writings')">writing for the
                public
                <i class="mi-arrow-right"></i>
            </button>
        </div>
    </div>
</div>
