<h1 appTypography textStyle="header-2">writing for the public</h1>

<div appTypography textStyle="body-small" class="writings-page__table">
    <table>
        <tr>
            <th class="writings-page__table__entry">entry</th>
            <th class="writings-page__table__description">description</th>
            <th class="writings-page__table__created">published</th>
        </tr>

        <tr *ngFor="let entry of entryHashes$ | async">
            <td class="writings-page__table__link">
                <button appBtn buttonType="link" [routerLink]="'/' + entry.redirect">
                    {{ entry.title }}
                </button>
            </td>
            <td>
                <a> {{ entry.description }} </a>
            </td>
            <td>
                <a>{{ entry.createdDate.toDate() | date }} </a>
            </td>
        </tr>
    </table>
</div>
