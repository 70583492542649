import { Component } from '@angular/core';
import { IClipping } from '../../modules/clipping/data/clipping.model';
import { CommonModule } from '@angular/common';
import { ClippingModule } from '../../modules/clipping/clipping.module';

@Component({
	selector: 'app-book-clippings',
	templateUrl: './book-clippings.component.html',
	styleUrls: ['./book-clippings.component.scss'],
	imports: [CommonModule, ClippingModule],
	standalone: true,
})
export class BookClippingsComponent {
	get filteredClippings() {
		return this._clippings
			.filter((clipping) => clipping.valid)
			.sort((a, b) => {
				let firstDate = new Date(a.created_date!);
				let secondDate = new Date(b.created_date!);
				if (firstDate < secondDate) {
					return 1;
				} else if (firstDate == secondDate) {
					return 0;
				} else {
					return -1;
				}
			});
	}

	private readonly _clippings: IClipping[] = [
		{
			title: '1984',
			author: 'George Orwell',
			location: 'Loc:48-49',
			Date: '2023-09-23T17:07:09Z',
			created_date: '',
			content: 'from the telescreen was still babbling away about pig',
			valid: false,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:1600-1601',
			Date: '2023-09-09T11:32:05Z',
			created_date: '09/09/2023',
			content:
				'This incident at our high school highlights the first of four major failings of the political Left on issues related to boys and men, which is a tendency to pathologize naturally occurring aspects of masculine identity, usually under the banner of toxic masculinity.',
			valid: true,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:1601-1605',
			Date: '2023-09-09T11:33:10Z',
			created_date: '09/09/2023',
			content:
				'The second progressive flaw is individualism; male problems are seen as the result of individual failings of one kind or another, rather than of structural challenges. Third is an unwillingness to acknowledge any biological basis for sex differences. Fourth is a fixed conviction that gender inequality can only run one way, that is, to the disadvantage of women. I will address each of these four progressive failings in turn here, before turning in chapter 9 to the equally harmful response of the political Right.',
			valid: true,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:1729-1730',
			Date: '2023-09-09T13:30:45Z',
			created_date: '09/09/2023',
			content:
				'But crucially, no account is taken of domains where women are doing better than men. As WEF’s number-crunchers explain, “The index assigns the same score to a country that has reached parity between women and men and one where women have surpassed men.”',
			valid: true,
		},
		{
			title: 'Man’s Search for Meaning',
			author: 'Viktor E. Frankl',
			location: 'Loc:79-81',
			Date: '2023-09-13T23:35:39Z',
			created_date: '13/09/2023',
			content:
				'“Don’t aim at success—the more you aim at it and make it a target, the more you are going to miss it. For success, like happiness, cannot be pursued; it must ensue, and it only does so as the unintended side-effect of one’s dedication to a cause greater than oneself or as the by-product of one’s surrender to a person other than oneself.',
			valid: true,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:340-341',
			Date: '2023-10-01T16:29:30Z',
			created_date: '',
			content: 'your investments have to produce a rate of return equal to inflation.',
			valid: false,
		},
		{
			title: 'Man’s Search for Meaning',
			author: 'Viktor E. Frankl',
			location: 'Loc:895-896',
			Date: '2023-09-14T22:10:15Z',
			created_date: '14/09/2023',
			content:
				'But when we ourselves were confronted with a great destiny and faced with the decision of meeting it with equal spiritual greatness, by then we had forgotten our youthful resolutions of long ago, and we failed.',
			valid: true,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:622-622',
			Date: '2023-10-01T18:26:37Z',
			created_date: '',
			content: '(buying on margin)',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:718-720',
			Date: '2023-10-01T18:40:34Z',
			created_date: '',
			content:
				'The consistent losers in the market, from my personal experience, are those who are unable to resist being swept up in some kind of tulip-bulb craze. It is not hard to make money in the market. What is hard to avoid is the alluring temptation to throw your money away on short, get-rich-quick speculative binges. It is an obvious lesson, but one frequently ignored.',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:1109-1114',
			Date: '2023-10-02T21:27:22Z',
			created_date: '',
			content:
				'In early 2000, 3Com sold 5 percent of its shares in Palm in an initial public offering and announced its intention to spin off all the remaining shares to the 3Com shareholders. Palm took off so fast that its market capitalization became twice as large as that of 3Com. The market value of the 95 percent of Palm owned by 3Com was almost $25 billion greater than the total market capitalization of 3Com itself. It was as if all of 3Com’s other assets had been worth a negative $25 billion. If you wanted to buy PalmPilot, you could have bought 3Com and owned the rest of 3Com’s business for minus $61 per share. In its mindless search for riches, the market created bizarre anomalies.',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:1384-1385',
			Date: '2023-10-03T19:45:27Z',
			created_date: '',
			content: 'But NO ONE PERSON OR INSTITUTION CONSISTENTLY KNOWS MORE THAN THE MARKET.',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:1598-1598',
			Date: '2023-10-03T20:09:17Z',
			created_date: '',
			content: 'Technical Analysis of Stock Trends,',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:1652-1654',
			Date: '2023-10-03T20:17:51Z',
			created_date: '',
			content:
				'The market may well be a most efficient mechanism. If some people know that the price will go to 40 tomorrow, it will go to 40 today.',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:1971-1973',
			Date: '2023-10-03T22:39:23Z',
			created_date: '',
			content:
				'The history of stock price movements contains no useful information that will enable an investor consistently to outperform a buy-and-hold strategy in managing a portfolio.',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:1981-1983',
			Date: '2023-10-03T22:40:39Z',
			created_date: '',
			content:
				'Indeed, a simple buy-and-hold strategy using a portfolio consisting of all the stocks in a broad stock-market index has provided investors with an average annual rate of return of about 10 percent over the past ninety years.',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:2047-2049',
			Date: '2023-10-03T22:48:59Z',
			created_date: '',
			content:
				'The psychologists conjecture that the persistent belief in the hot hand could be due to memory bias. If long sequences of hits or misses are more memorable than alternating sequences, observers are likely to overestimate the correlation between successive shots.',
			valid: false,
		},
		{
			title: 'Man’s Search for Meaning',
			author: 'Viktor E. Frankl',
			location: 'Loc:1019-1021',
			Date: '2023-09-15T23:30:24Z',
			created_date: '15/09/2023',
			content:
				'Therefore, it was necessary to face up to the full amount of suffering, trying to keep moments of weakness and furtive tears to a minimum. But there was no need to be ashamed of tears, for tears bore witness that a man had the greatest of courage, the courage to suffer.',
			valid: true,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:2652-2652',
			Date: '2023-10-04T21:55:53Z',
			created_date: '',
			content: 'Portfolio Selection',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:2735-2736',
			Date: '2023-10-05T20:35:43Z',
			created_date: '',
			content:
				'The paradoxical result of this analysis is that overall portfolio risk is reduced by the addition of a small amount of riskier foreign securities.',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:3799-3799',
			Date: '2023-10-07T18:23:57Z',
			created_date: '',
			content: 'Principles',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:4089-4090',
			Date: '2023-10-07T20:10:27Z',
			created_date: '',
			content: 'EXERCISE 4: LEARN HOW TO DODGE THE TAX COLLECTOR',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:5099-5099',
			Date: '2023-10-08T09:38:12Z',
			created_date: '',
			content: 'LIFE-CYCLE FUNDS',
			valid: false,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:5632',
			Date: '2023-10-08T12:59:33Z',
			created_date: '',
			content: '',
			valid: false,
		},
		{
			title: 'Anger',
			author: 'Richard E Passingham',
			location: 'Loc:243-244',
			Date: '2024-02-04T15:14:44Z',
			created_date: '',
			content:
				'“I want to punish you, I want to make you suffer because you have made me suffer. And when I see you suffer a lot, I will feel better.”',
			valid: false,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:348-350',
			Date: '2023-12-02T01:00:11Z',
			created_date: '',
			content:
				'Remember how you feel when you hear the same thing concerning other people. Transfer that feeling to your own current circumstances. Learn to accept events, even death, with intelligence.',
			valid: false,
		},
		{
			title: 'Man’s Search for Meaning',
			author: 'Viktor E. Frankl',
			location: 'Loc:1345-1346',
			Date: '2023-09-16T10:28:30Z',
			created_date: '16/09/2023',
			content:
				'“Live as if you were living already for the second time and as if you had acted the first time as wrongly as you are about to act now!”\nIt seems to me that there is nothing which would stimulate a man’s sense of responsibleness more than this maxim, which invites him to imagine first that the present is past and, second, that the past may yet be changed and amended. Such a precept confronts him with life’s finiteness as well as the finality of what he makes out of both his life and himself.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:376-377',
			Date: '2023-12-02T01:06:53Z',
			created_date: '',
			content:
				'Unless we fully give ourselves over to our endeavors, we are hollow, superficial people and we never develop our natural gifts. We’ve all known people who, like monkeys, mimic whatever seems novel and flashy at the moment.',
			valid: false,
		},
		{
			title: 'Man’s Search for Meaning',
			author: 'Viktor E. Frankl',
			location: 'Loc:1380-1381',
			Date: '2023-09-16T10:44:39Z',
			created_date: '16/09/2023',
			content:
				'We must never forget that we may also find meaning in life even when confronted with a hopeless situation, when facing a fate that cannot be changed.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:481-483',
			Date: '2023-12-02T11:19:45Z',
			created_date: '',
			content:
				'So much attention is given to the moral importance of our deeds and their effects. Those who seek to live the higher life also come to understand the oft-ignored moral power of our words.',
			valid: false,
		},
		{
			title: 'Man’s Search for Meaning',
			author: 'Viktor E. Frankl',
			location: 'Loc:1452-1455',
			Date: '2023-09-16T10:53:51Z',
			created_date: '16/09/2023',
			content:
				'This ultimate meaning necessarily exceeds and surpasses the finite intellectual capacities of man; in logotherapy, we speak in this context of a super-meaning. What is demanded of man is not, as some existential philosophers teach, to endure the meaninglessness of life, but rather to bear his incapacity to grasp its unconditional meaningfulness in rational terms. Logos is deeper than logic.',
			valid: true,
		},
		{
			title: 'Man’s Search for Meaning',
			author: 'Viktor E. Frankl',
			location: 'Loc:1477-1478',
			Date: '2023-09-16T11:26:52Z',
			created_date: '16/09/2023',
			content: 'For, in the past, nothing is irretrievably lost but everything irrevocably stored.',
			valid: true,
		},
		{
			title: 'Man’s Search for Meaning',
			author: 'Viktor E. Frankl',
			location: 'Loc:1702-1703',
			Date: '2023-09-16T12:03:56Z',
			created_date: '16/09/2023',
			content:
				'In fact, the drug scene is one aspect of a more general mass phenomenon, namely the feeling of meaninglessness resulting from a frustration of our existential needs which in turn has become a universal phenomenon in our industrial societies.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:833-835',
			Date: '2023-12-03T08:09:56Z',
			created_date: '',
			content:
				'The untrained response to robbers and thugs and to those who otherwise err is outrage and retribution. Wrongdoers need to be rightly understood to form the correct response to their behavior.',
			valid: false,
		},
		{
			title: 'Self Reliance',
			author: 'Ralph Waldo Emerson',
			location: 'Loc:49-50',
			Date: '2023-09-16T13:57:54Z',
			created_date: '16/09/2023',
			content: 'In every work of genius we recognize our own rejected thoughts: they come back to us with a certain alienated majesty.',
			valid: true,
		},
		{
			title: 'Self Reliance',
			author: 'Ralph Waldo Emerson',
			location: 'Loc:105-106',
			Date: '2023-09-16T14:47:12Z',
			created_date: '16/09/2023',
			content: 'These are the voices which we hear in solitude, but they grow faint and inaudible as we enter into the world.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:218-218',
			Date: '2023-09-16T16:03:41Z',
			created_date: '16/09/2023',
			content: 'You will never fully convince someone that he is wrong; only reality can.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:229-230',
			Date: '2023-09-16T16:07:58Z',
			created_date: '16/09/2023',
			content:
				'Returning to our interventionistas, we saw that people don’t learn so much from their—and other people’s—mistakes; rather it is the system that learns by selecting those less prone to a certain class of mistakes and eliminating others.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:243-244',
			Date: '2023-09-16T16:09:39Z',
			created_date: '16/09/2023',
			content:
				'Via negativa: the principle that we know what is wrong with more clarity than what is right, and that knowledge grows by subtraction.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:244-245',
			Date: '2023-09-16T16:09:23Z',
			created_date: '16/09/2023',
			content: 'Actions that remove are more robust than those that add because addition may have unseen, complicated feedback loops.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:251-252',
			Date: '2023-09-16T23:20:31Z',
			created_date: '16/09/2023',
			content: '[...] risk transfer blows up systems.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:252-252',
			Date: '2023-09-16T23:21:00Z',
			created_date: '16/09/2023',
			content: 'And the very idea of law, divine or otherwise, resides in fixing imbalances and remedying such asymmetries.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:309-312',
			Date: '2023-09-16T23:29:46Z',
			created_date: '16/09/2023',
			content:
				'Effectively, there is no democracy without such an unconditional symmetry in the rights to express yourself, and the gravest threat is the slippery slope in the attempts to limit speech on grounds that some of it may hurt some people’s feelings. Such restrictions do not necessarily come from the state itself, rather from the forceful establishment of an intellectual monoculture by an overactive thought police in the media and cultural life.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:316-317',
			Date: '2023-09-16T23:31:25Z',
			created_date: '16/09/2023',
			content: '“Act only in accordance with that maxim through which you can at the same time will that it will become a universal law,”',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:360-361',
			Date: '2023-09-17T10:51:36Z',
			created_date: '17/09/2023',
			content: 'Recall that it is at the foundation of evolution that systems get smart by elimination.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:398-400',
			Date: '2023-09-17T11:00:34Z',
			created_date: '17/09/2023',
			content:
				'By definition, what works cannot be irrational; about every single person I know who has chronically failed in business shares that mental block, the failure to realize that if something stupid works (and makes money), it cannot be stupid',
			valid: true,
		},
		{
			title: 'Cognitive Neuroscience:  A Very Short Introduction',
			author: 'Richard E Passingham',
			location: 'Loc:1631-1633',
			Date: '2024-03-10T18:04:01Z',
			created_date: '',
			content:
				'But we cannot devote the same degree of attention to all the things that we are doing. As mentioned in Chapter 3, there is a penalty for multitasking. However, it is reduced if one or more of the tasks is well practised.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:413-414',
			Date: '2023-09-17T11:04:33Z',
			created_date: '17/09/2023',
			content:
				'For the professionally asymmetric person is rare and has been so in history, and even in the present. He causes a lot of problems, but he is rare.',
			valid: true,
		},
		{
			title: 'Cognitive Neuroscience:  A Very Short Introduction',
			author: 'Richard E Passingham',
			location: 'Loc:1719-1720',
			Date: '2024-03-10T18:13:59Z',
			created_date: '',
			content: 'It pays to try things out in the head rather than in practice because it avoids the error.',
			valid: false,
		},
		{
			title: 'Cognitive Neuroscience:  A Very Short Introduction',
			author: 'Richard E Passingham',
			location: 'Loc:2164-2165',
			Date: '2024-03-10T20:33:05Z',
			created_date: '',
			content: 'Magneto-encephalography',
			valid: false,
		},
		{
			title: 'Crossing the Chasm, 3rd Edition',
			author: 'Geoffrey A. Moore',
			location: 'Loc:2108-2112',
			Date: '2023-10-21T10:23:36Z',
			created_date: '',
			content:
				'There are four domains of value in high-tech marketing: technology, product, market, and company. As products move through the Technology Adoption Life Cycle, the domain of greatest value to the customer changes. In the early market, where decisions are dominated by technology enthusiasts and visionaries, the key value domains are technology and product. In the mainstream, where decisions are dominated by pragmatists and conservatives, the key domains are market and company. Crossing the chasm, in this context, represents a transition from product-based to market-based values.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:422-424',
			Date: '2023-09-17T11:09:47Z',
			created_date: '17/09/2023',
			content:
				'Replacing the “natural,” that is age-old, processes that have survived trillions of high-dimensional stressors with something in a “peer-reviewed” journal that may not survive replication or statistical scrutiny is neither science nor good practice.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:424-425',
			Date: '2023-09-17T11:11:45Z',
			created_date: '17/09/2023',
			content:
				'At the time of writing, science has been taken over by vendors using it to sell products (like margarine or genetically modified solutions) and, ironically, the skeptical enterprise is being used to silence skeptics.',
			valid: true,
		},
		{
			title: 'Do What Matters Most',
			author: 'Steven R Shallenberger',
			location: 'Loc:589-590',
			Date: '2023-10-31T11:58:47Z',
			created_date: '',
			content: 'Only 2 percent of executives, managers, and employees have a written personal vision.',
			valid: false,
		},
		{
			title: 'Do What Matters Most',
			author: 'Steven R Shallenberger',
			location: 'Loc:1175-1177',
			Date: '2023-11-02T12:18:33Z',
			created_date: '',
			content:
				'If you are in a relationship, both people can do this individually and then come together to compare lists and look for overlaps and make those a higher priority. Your bucket list can be part of your vision board or collocated with your written vision.',
			valid: false,
		},
		{
			title: 'Do What Matters Most',
			author: 'Steven R Shallenberger',
			location: 'Loc:1774-1775',
			Date: '2023-11-05T12:12:04Z',
			created_date: '',
			content: 'We could fill a book with emails from people who have shared the impact the big three have had in their lives.',
			valid: false,
		},
		{
			title: 'Do What Matters Most',
			author: 'Steven R Shallenberger',
			location: 'Loc:1775-1776',
			Date: '2023-11-05T12:12:24Z',
			created_date: '',
			content: 'The hope in sharing these experiences is to foster a belief and confidence that you can do it.',
			valid: false,
		},
		{
			title: 'Do What Matters Most',
			author: 'Steven R Shallenberger',
			location: 'Loc:2000-2001',
			Date: '2023-11-06T13:23:30Z',
			created_date: '',
			content: 'doing the right thing at the right time regardless of how we feel about it.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:431-432',
			Date: '2023-09-17T11:13:40Z',
			created_date: '17/09/2023',
			content: 'Those who talk should do and only those who do should talk',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:532-533',
			Date: '2023-09-17T11:37:55Z',
			created_date: '17/09/2023',
			content: 'The villainous takes the short road, virtue the longer one. In other words, cutting corners is dishonest.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:763-765',
			Date: '2023-09-17T12:39:30Z',
			created_date: '17/09/2023',
			content:
				'Beware of the person who gives advice, telling you that a certain action on your part is “good for you” while it is also good for him, while the harm to you doesn’t directly affect him.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:817-818',
			Date: '2023-09-17T12:49:06Z',
			created_date: '17/09/2023',
			content:
				'The ethical is always more robust than the legal. Over time, it is the legal that should converge to the ethical, never the reverse.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:841-842',
			Date: '2023-09-17T12:57:30Z',
			created_date: '17/09/2023',
			content: 'No person in a transaction should have certainty about the outcome while the other one has uncertainty.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:868-869',
			Date: '2023-09-17T13:00:32Z',
			created_date: '17/09/2023',
			content: 'Sympathy for all would be tyranny for thee, my good neighbor.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:1025-1026',
			Date: '2023-09-17T13:22:12Z',
			created_date: '17/09/2023',
			content: 'The main idea behind complex systems is that the ensemble behaves in ways not predicted by its components.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:1026-1029',
			Date: '2023-09-17T13:25:37Z',
			created_date: '17/09/2023',
			content:
				'The interactions matter more than the nature of the units. Studying individual ants will almost never give us a clear indication of how the ant colony operates. For that, one needs to understand an ant colony as an ant colony, no less, no more, not a collection of ants. This is called an “emergent” property of the whole, by which parts and whole differ because what matters are the interactions between such parts. And interactions can obey very simple rules.',
			valid: true,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:436-437',
			Date: '2023-09-03T12:41:49Z',
			created_date: '',
			content: 'How to Write Short: Word Craft for Fast Times by Roy Peter Clark,',
			valid: false,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:461-461',
			Date: '2023-09-03T12:45:43Z',
			created_date: '',
			content: '',
			valid: false,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:836-838',
			Date: '2023-09-03T22:39:50Z',
			created_date: '',
			content:
				'This is one reason why President Obama launched his 2014 initiative, My Brother’s Keeper, which lives on as the MBK Alliance in the Obama Foundation.²⁸ This focus on boys and men has been criticized, for example, by the Institute for Women’s Policy Research, for drawing attention away from the challenges faced by Black women.²⁹',
			valid: false,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:1346-1346',
			Date: '2023-09-06T22:22:26Z',
			created_date: '',
			content: '“naturalistic fallacy,”',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:1346-1347',
			Date: '2023-09-17T17:46:30Z',
			created_date: '17/09/2023',
			content: 'The average behavior of the market participant will not allow us to understand the general behavior of the market.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:1381-1381',
			Date: '2023-09-17T17:53:08Z',
			created_date: '17/09/2023',
			content: 'Under the right market structure, a collection of idiots produces a well-functioning market.',
			valid: true,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:1720-1723',
			Date: '2023-09-09T13:29:29Z',
			created_date: '',
			content:
				'Almost every major think tank and international organization in the world produced reports on the negative impact of the pandemic on women, many written in a hyperbolic tone. By comparison, the much higher risk of death from COVID-19 for men warranted barely a mention. Nor the sharp drop in male college enrollment. Of course, the pandemic was mostly just bad all around. But it was bad for women in some ways, and bad for men in other ways. We can hold two thoughts in our head at the same time.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:2126-2127',
			Date: '2023-09-20T22:12:39Z',
			created_date: '20/09/2023',
			content:
				'Now, crucially, time is equivalent to disorder, and resistance to the ravages of time, that is, what we gloriously call survival, is the ability to handle disorder.',
			valid: true,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:2126-2128',
			Date: '2023-09-09T14:49:46Z',
			created_date: '',
			content:
				'Stigma is one major challenge. Many of the men had been advised to make sure they were never alone with a child, and to be wary of any physical contact. (Just as I was writing this chapter, my son called to say he had been turned down for a childcare job because the parents were uncomfortable leaving their children with a man. “At least they were honest about it,” he said.)',
			valid: false,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:2430-2432',
			Date: '2023-09-09T18:10:47Z',
			created_date: '',
			content:
				'Florence Nightingale set the tone right from the beginning, when she effectively founded modern nursing in the nineteenth century, opposing men in the profession on the grounds that with their “hard and horny hands” they were not suited to “touch, bathe and dress wounded limbs, however gentle their hearts may be.”⁵⁵',
			valid: false,
		},
		{
			title: 'Of Boys and Men',
			author: 'Richard V. Reeves',
			location: 'Loc:436',
			Date: '2023-09-03T12:41:49Z',
			created_date: '',
			content: 'TO READ',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:85-85',
			Date: '2023-10-22T00:57:12Z',
			created_date: '',
			content: '“Mowing the lawn”',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:380-382',
			Date: '2023-10-24T06:31:39Z',
			created_date: '',
			content:
				'This is one of the main reasons why ethnic cleansing is an ideology that is regarded by the international community, in the aftermath of the Second World War, as a hideous crime and moreover one that can lead to genocide—since with both crimes you have to dehumanize your victim in order to implement your vision of ethnic purity.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:2270-2270',
			Date: '2023-09-21T22:10:32Z',
			created_date: '21/09/2023',
			content: 'The good is not as good as the absence of bad,',
			valid: true,
		},
		{
			title: '1984',
			author: 'George Orwell',
			location: 'Loc:489-490',
			Date: '2023-09-23T18:42:15Z',
			created_date: '23/09/2023',
			content: '“Who controls the past,” ran the Party slogan, “controls the future: who controls the present controls the past.”',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:2845-2845',
			Date: '2023-09-23T10:48:42Z',
			created_date: '23/09/2023',
			content: 'Courage is the only virtue you cannot fake.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3109-3109',
			Date: '2023-09-23T11:49:27Z',
			created_date: '23/09/2023',
			content: 'Love without sacrifice is theft',
			valid: true,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:747-747',
			Date: '2023-10-24T07:20:24Z',
			created_date: '',
			content: 'Yosef Grodzinsky',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:749-749',
			Date: '2023-10-24T07:20:48Z',
			created_date: '',
			content: '“Good Human Material.”',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:880-882',
			Date: '2023-10-24T07:41:12Z',
			created_date: '',
			content:
				'They are becoming delegitimized. They are worried about it, but it is going to continue. It’s inherent with a policy of expansion, disregard of international opinion, violations of international law, you can get away with it as long as you have the biggest thug on the block protecting you.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3127-3128',
			Date: '2023-09-23T11:50:55Z',
			created_date: '23/09/2023',
			content:
				'But it remains that nobody in the Vatican seems to ever take chances by going first to the Lord, subsequently to the doctor, and, what is even more surprising, nobody seems to see a conflict with such inversion of the logical sequence.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3186-3187',
			Date: '2023-09-23T12:58:53Z',
			created_date: '23/09/2023',
			content: 'Survival comes first, truth, understanding, and science later.',
			valid: true,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:1036-1036',
			Date: '2023-10-24T08:16:08Z',
			created_date: '',
			content: 'Something simple like boycotting products is an entry point to bringing out all of these issues.',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:1051-1053',
			Date: '2023-10-24T08:20:30Z',
			created_date: '',
			content:
				'For example, if the significant domestic lobbies in the USA, the business lobbies, which just overwhelm everything else, if they came to the opinion that US policy in support of Israel is harmful to their interests, they would change it very quickly. That can be done.',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:1137-1138',
			Date: '2023-10-25T12:01:30Z',
			created_date: '',
			content: 'Chronicle of Higher Education,',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:1187-1188',
			Date: '2023-10-25T12:13:14Z',
			created_date: '',
			content: 'It’s not just three billion dollars in military aid, it’s also the vetoes, and the ideological support.',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:1199-1200',
			Date: '2023-10-26T16:23:39Z',
			created_date: '',
			content:
				'The scenes for example during Cast Lead, the brutal attack on Gaza with Israelis sitting on beach chairs on the hills, applauding every time a bomb fell. This is beyond obscenity',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3252-3252',
			Date: '2023-09-23T15:06:30Z',
			created_date: '23/09/2023',
			content: 'How much you truly “believe” in something can be manifested only through what you are willing to risk for it.',
			valid: true,
		},
		{
			title: 'One, No One, and One Hundred Thousand',
			author: 'Luigi Pirandello',
			location: 'Loc:137-137',
			Date: '2024-01-21T09:35:54Z',
			created_date: '',
			content: 'become acquainted with his new and blemished self.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3457-3458',
			Date: '2023-09-23T16:07:04Z',
			created_date: '23/09/2023',
			content: 'In a strategy that entails ruin, benefits never offset risks of ruin.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3459-3459',
			Date: '2023-09-23T16:07:25Z',
			created_date: '23/09/2023',
			content: 'Every single risk you take adds up to reduce your life expectancy.',
			valid: true,
		},
		{
			title: '1984',
			author: 'George Orwell',
			location: 'Loc:1441-1442',
			Date: '2023-09-24T12:35:20Z',
			created_date: '24/09/2023',
			content: 'in moments of crisis one is never fighting against an external enemy but always against one’s own body.',
			valid: true,
		},
		{
			title: '1984',
			author: 'George Orwell',
			location: 'Loc:2942-2944',
			Date: '2023-09-24T17:29:30Z',
			created_date: '24/09/2023',
			content:
				'He did not see that the continuity of an oligarchy need not be physical, nor did he pause to reflect that hereditary aristocracies have always been shortlived, whereas adoptive organizations such as the Catholic Church have sometimes lasted for hundreds or thousands of years.',
			valid: true,
		},
		{
			title: 'Security Analysis',
			author: 'Benjamin Graham',
			location: 'Loc:1149-1149',
			Date: '2023-12-26T16:18:29Z',
			created_date: '',
			content: 'prophylactic',
			valid: false,
		},
		{
			title: '1984',
			author: 'George Orwell',
			location: 'Loc:3780-3781',
			Date: '2023-09-24T19:13:02Z',
			created_date: '24/09/2023',
			content: 'You are under the impression that hatred is more exhausting than love. Why should it be?',
			valid: true,
		},
		{
			title: 'Security Analysis',
			author: 'Benjamin Graham',
			location: 'Loc:3889-3889',
			Date: '2023-12-30T23:23:17Z',
			created_date: '',
			content: 'interest was substantially above our minimum and indicated a satisfactory',
			valid: false,
		},
		{
			title: 'Security Analysis',
			author: 'Benjamin Graham',
			location: 'Loc:4904-4905',
			Date: '2024-01-07T11:18:06Z',
			created_date: '',
			content:
				'“a convenient omnibus word, with perhaps an admixture of euphemism—that is, a desire to lend a certain respectability to financial dealings of miscellaneous character.”',
			valid: false,
		},
		{
			title: 'The Communist Manifesto',
			author: 'Karl Marx',
			location: 'Loc:143-144',
			Date: '2023-09-25T23:46:08Z',
			created_date: '25/09/2023',
			content:
				'The less the skill and exertion of strength implied in manual labor, in other words, the more modern industry becomes developed, the more is the labor of men superseded by that of women.',
			valid: true,
		},
		{
			title: 'Self Reliance',
			author: 'Ralph Waldo Emerson',
			location: 'Loc:48-49',
			Date: '2023-09-16T13:56:46Z',
			created_date: '',
			content: 'Yet he dismisses without notice his thought, because it is his.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:297-297',
			Date: '2023-09-30T00:57:37Z',
			created_date: '30/09/2023',
			content: 'Go to bed angry. Wake up with a plan.',
			valid: true,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:304-305',
			Date: '2023-09-30T12:17:31Z',
			created_date: '30/09/2023',
			content: 'What I meant when I said “I don’t have time” is that every minute that passes I’m disappointing someone',
			valid: true,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:601-607',
			Date: '2023-09-30T15:01:48Z',
			created_date: '30/09/2023',
			content:
				'I count the deaths at each transition: three lost on the way to the grocery store, four on a trip to the creek. What if no one watches the mower mowing? What if no one sees his body move across the street? I am one step ahead of howling, one breath shy of grief. Take my hands and reveal the living. Rip the seam out of the story sewn. It is one thing to imagine (it is another to walk among the graves). At night I tuck my dread into the covers, check its pulse before the dawn. You ask: how can I keep this tired practice? I ask: how can I leave this haunting place?',
			valid: true,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:428-434',
			Date: '2023-10-01T16:50:57Z',
			created_date: '01/10/2023',
			content:
				'It is analogous to entering a newspaper beauty-judging contest in which one must select the six prettiest faces out of a hundred photographs, with the prize going to the person whose selections most nearly conform to those of the group as a whole. The smart player recognizes that personal criteria of beauty are irrelevant in determining the contest winner. A better strategy is to select those faces the other players are likely to fancy. This logic tends to snowball. After all, the other participants are likely to play the game with at least as keen a perception. Thus, the optimal strategy is not to pick those faces the player thinks are prettiest, or those the other players are likely to fancy, but rather to predict what the average opinion is likely to be about what the average opinion will be, or to proceed even further along this sequence. So much for British beauty contests.',
			valid: true,
		},
		{
			title: 'A Random Walk Down Wall Street',
			author: 'Burton G. Malkiel',
			location: 'Loc:2324-2325',
			Date: '2023-10-03T23:32:32Z',
			created_date: '03/10/2023',
			content: '“Worldly wisdom teaches that it is better for reputation to fail conventionally than to succeed unconventionally.”',
			valid: true,
		},
		{
			title: 'The Little Prince',
			author: 'Antoine de Saint Exupéry',
			location: 'Loc:545-550',
			Date: '2023-10-22T11:02:20Z',
			created_date: '22/10/2023',
			content:
				'You are beautiful, but you are empty," he went on. "One could not die for you. To be sure, an ordinary passerby would think that my rose looked just like you--the rose that belongs to me. But in herself alone she is more important than all the hundreds of you other roses: because it is she that I have watered; because it is she that I have put under the glass globe; because it is she that I have sheltered behind the screen; because it is for her that I have killed the caterpillars (except the two or three that we saved to become butterflies); because it is she that I have listened to, when she grumbled, or boasted, or ever sometimes when she said nothing. Because she is my rose.',
			valid: true,
		},
		{
			title: 'The Little Prince',
			author: 'Antoine de Saint Exupéry',
			location: 'Loc:551-551',
			Date: '2023-10-22T11:02:41Z',
			created_date: '22/10/2023',
			content: 'It is only with the heart that one can see rightly; what is essential is invisible to the eye.',
			valid: true,
		},
		{
			title: 'The Little Prince',
			author: 'Antoine de Saint Exupéry',
			location: 'Loc:552-553',
			Date: '2023-10-22T11:03:27Z',
			created_date: '22/10/2023',
			content: 'It is the time you have wasted for your rose that makes your rose so important.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:459-459',
			Date: '2023-09-17T11:19:47Z',
			created_date: '',
			content: 'pathemata mathemata',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:504-504',
			Date: '2023-09-17T11:34:19Z',
			created_date: '',
			content: 'deontic libertarianism',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:454-456',
			Date: '2023-10-24T06:42:24Z',
			created_date: '24/10/2023',
			content:
				'Israelis do not find it therefore at all bizarre or unacceptable that determining the results of a democratic process by first determining by force who makes up the electorate gets the desired result: a purely Jewish state in a binational country.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:656-657',
			Date: '2023-09-17T12:09:17Z',
			created_date: '',
			content: 'Black Swan invocation).',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:717-717',
			Date: '2023-09-17T12:33:07Z',
			created_date: '',
			content: 'how a collection of units doesn’t behave like a sum of units,',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:570-572',
			Date: '2023-10-24T06:58:06Z',
			created_date: '24/10/2023',
			content:
				'Heading toward 2020, we will all most probably face a racist, ultra-capitalist, and more expanded Israel still busy ethnically cleansing Palestine. There is however a good chance that such a state will become a global pariah and the people around the world will ask their “leaders” to act and end any relations they have with it.',
			valid: true,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:711-712',
			Date: '2023-10-24T07:15:49Z',
			created_date: '24/10/2023',
			content:
				'That’s why Europe was the most violent place in the world for centuries. Once a state is established, any citizen is a citizen of the state.',
			valid: true,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:721-722',
			Date: '2023-10-24T07:17:27Z',
			created_date: '24/10/2023',
			content:
				'Paradoxically it is used by Israel in an attempt to stifle any criticism of the state and its ideology. If you chastise Israel, you assault the Jewish state and by association you attack Judaism.',
			valid: true,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:893-898',
			Date: '2023-10-24T07:46:54Z',
			created_date: '24/10/2023',
			content:
				'The Israelis now have to brand two commodities. They have to market to the world the legitimacy of the state in a world that finds it very difficult to accept it. But then they have also a domestic branding to do. They have to explain to the poor and marginalized Jews why belonging to the master race has not improved their socio-economic standards of living. Why do they still live in impoverished development towns? Why is their culture not represented in the European-dominated and hegemonic culture? Israeli strategists will tell you that they have dealt with this by having a common enemy, a security issue, by having a war on Islam.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:903-904',
			Date: '2023-09-17T13:06:34Z',
			created_date: '',
			content:
				'it is obvious, even trite, from the well-known behavior of crowds in the “anonymity” of big cities compared to groups in small villages.',
			valid: false,
		},
		{
			title: 'On Palestine',
			author: 'Noam Chomsky',
			location: 'Loc:1014-1017',
			Date: '2023-10-24T08:13:37Z',
			created_date: '24/10/2023',
			content:
				'The USA supports Israel not out of benevolence, but because it’s useful for US policies. So yes, they do overlap a lot. Also cultural relations, Christian Zionism for example, is part of the demographic base of the Republican Party—extremely anti-Semitic, but pro-Israel. All these things have to be addressed.',
			valid: true,
		},
		{
			title: 'Do What Matters Most',
			author: 'Steven R Shallenberger',
			location: 'Loc:330-331',
			Date: '2023-10-30T14:06:07Z',
			created_date: '30/10/2023',
			content:
				'The internal feeling associated with complacency is a feeling of comfort or a feeling of being in cruise control. The complacent mindset is dangerous because it often closes the door to the idea that there might be a better way.',
			valid: true,
		},
		{
			title: 'Do What Matters Most',
			author: 'Steven R Shallenberger',
			location: 'Loc:369-371',
			Date: '2023-10-30T14:09:59Z',
			created_date: '30/10/2023',
			content:
				'We will relentlessly chase perfection, knowing full well we will not catch it because nothing is perfect. But we will relentlessly chase it because, in the process, we will catch excellence.\nI am not remotely interested in just being good.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:1358-1358',
			Date: '2023-09-17T17:48:49Z',
			created_date: '',
			content: 'curse of dimensionality',
			valid: false,
		},
		{
			title: 'Brave New World',
			author: 'Aldous Huxley',
			location: 'Loc:43-45',
			Date: '2023-11-18T09:34:09Z',
			created_date: '18/11/2023',
			content:
				'For particulars, as every one knows, make for virtue and happiness; generalities are intellectually necessary evils. Not philosophers, but fret-sawyers and stamp collectors compose the backbone of society.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:1448-1449',
			Date: '2023-09-17T19:18:44Z',
			created_date: '',
			content:
				'There is a trader’s expression: “Never buy when you can rent the three F s: what you Float, what you Fly, and what you…(that something else)',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:1795-1795',
			Date: '2023-09-19T22:18:58Z',
			created_date: '',
			content: 'ergodicity,',
			valid: false,
		},
		{
			title: 'Brave New World',
			author: 'Aldous Huxley',
			location: 'Loc:961-963',
			Date: '2023-11-19T12:36:43Z',
			created_date: '19/11/2023',
			content:
				'Mental excess could produce, for its own purposes, the voluntary blindness and deafness of deliberate solitude, the artificial impotence of asceticism.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:2146-2146',
			Date: '2023-09-20T22:16:11Z',
			created_date: '',
			content: 'That which is “Lindy” is what ages in reverse, i.e., its life expectancy lengthens with time, conditional on survival.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:2167-2168',
			Date: '2023-09-20T22:19:57Z',
			created_date: '',
			content: 'You can define a free person precisely as someone whose fate is not centrally or directly dependent on peer assessment.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:2173-2174',
			Date: '2023-09-20T22:21:32Z',
			created_date: '',
			content:
				'Being reviewed or assessed by others matters if and only if one is subjected to the judgment of future—not just present—others.',
			valid: false,
		},
		{
			title: 'Brave New World',
			author: 'Aldous Huxley',
			location: 'Loc:2375-2377',
			Date: '2023-11-26T08:35:14Z',
			created_date: '26/11/2023',
			content:
				'One of the principal functions of a friend is to suffer (in a milder and symbolic form) the punishments that we should like, but are unable, to inflict upon our enemies.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:46-48',
			Date: '2023-11-28T11:41:31Z',
			created_date: '28/11/2023',
			content:
				'Musonius Rufus’s works, which survive in Greek, include arguments in favor of equal education for women and against the sexual double standard in marriage, and Epictetus’s famous egalitarian spirit may have been nurtured under his tutelage.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:86-89',
			Date: '2023-11-29T12:05:44Z',
			created_date: '29/11/2023',
			content:
				'In other important ways, however, Epictetus is very traditional and uncontemporary. Whereas our society (practically, if not always explicitly) regards professional achievement, wealth, power, and fame as desirable and admirable, Epictetus views these as incidental and irrelevant to true happiness. What matters most is what sort of person you are becoming, what sort of life you are living.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:89-90',
			Date: '2023-11-29T12:05:56Z',
			created_date: '29/11/2023',
			content: 'First, say to yourself what you would be; then do what you have to do.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3183-3183',
			Date: '2023-09-23T14:58:14Z',
			created_date: '',
			content: 'But actions that harm you are detectable, if not observable. We will see in the next chapter that, unless',
			valid: false,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:127-128',
			Date: '2023-11-29T12:12:00Z',
			created_date: '29/11/2023',
			content:
				'Our desires and aversions are mercurial rulers. They demand to be pleased. Desire commands us to run off and get what we want. Aversion insists that we must avoid the things that repel us.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3192-3192',
			Date: '2023-09-23T14:59:04Z',
			created_date: '',
			content: 'ergodic,”',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3217-3219',
			Date: '2023-09-23T15:02:34Z',
			created_date: '',
			content:
				'you will not have an idea about what people really think, what predicts people’s actions, merely by asking them—they themselves don’t necessarily know. What matters, in the end, is what they pay for goods, not what they say they “think” about them, or the various possible reasons they give you or themselves for that.',
			valid: false,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:149-150',
			Date: '2023-11-29T12:14:57Z',
			created_date: '29/11/2023',
			content: 'Things and people are not what we wish them to be nor what they seem to be. They are what they are.',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3283-3283',
			Date: '2023-09-23T15:11:57Z',
			created_date: '',
			content: 'Rationality does not depend on explicit verbalistic explanatory factors; it is only what aids survival, what avoids ruin.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3345-3345',
			Date: '2023-09-23T15:43:01Z',
			created_date: '',
			content: 'ruin problems and repetition of exposures.',
			valid: false,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:3456-3456',
			Date: '2023-09-23T16:07:12Z',
			created_date: '',
			content: 'One may be risk loving yet completely averse to ruin.',
			valid: false,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:231-233',
			Date: '2023-11-30T12:18:37Z',
			created_date: '30/11/2023',
			content:
				'Refrain from trying to win other people’s approval and admiration. You are taking a higher road. Don’t long for others to see you as sophisticated, unique, or wise. In fact, be suspicious if you appear to others as someone special. Be on your guard against a false sense of self-importance.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:254-255',
			Date: '2023-11-30T12:20:58Z',
			created_date: '30/11/2023',
			content:
				'Other people’s views and troubles can be contagious. Don’t sabotage yourself by unwittingly adopting negative, unproductive attitudes through your associations with others.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:351-352',
			Date: '2023-12-02T01:00:32Z',
			created_date: '02/12/2023',
			content:
				'Evil does not naturally dwell in the world, in events, or in people. Evil is a by-product of forgetfulness, laziness, or distraction: it arises when we lose sight of our true aim in life.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:456-460',
			Date: '2023-12-02T11:16:37Z',
			created_date: '02/12/2023',
			content:
				'In any events, however seemingly dire, there is nothing to prevent us from searching for its hidden opportunity. It is a failure of the imagination not to do so. But to seek out the opportunity in situations requires a great deal of courage, for most people around you will persist in interpreting events in the grossest terms: success or failure, good or bad, right or wrong. These simplistic, polarized categories obscure more creative—and useful—interpretations of events that are far more advantageous and interesting!',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:533-535',
			Date: '2023-12-02T11:52:53Z',
			created_date: '02/12/2023',
			content:
				'So, if anyone should tell you that a particular person has spoken critically of you, don’t bother with excuses or defenses. Just smile and reply, “I guess that person doesn’t know about all my other faults. Otherwise, he wouldn’t have mentioned only these.”',
			valid: true,
		},
		{
			title: 'Skin in the Game',
			author: 'Taleb, Nassim Nicholas [Taleb, Nassim Nicholas]',
			location: 'Loc:244-245',
			Date: '2023-09-16T16:09:45Z',
			created_date: '',
			content:
				'know that something is wrong than to find the fix. Actions that remove are more robust than those that add because addition may have unseen, complicated feedback loops.',
			valid: false,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:756-756',
			Date: '2023-12-03T07:59:17Z',
			created_date: '03/12/2023',
			content: 'Be suspicious of convention.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:758-760',
			Date: '2023-12-03T07:59:53Z',
			created_date: '03/12/2023',
			content:
				'Conventional thinking—its means and ends—is essentially uncreative and uninteresting. Its job is to preserve the status quo for overly self-defended individuals and institutions.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Epictetus',
			location: 'Loc:885-886',
			Date: '2023-12-03T08:18:21Z',
			created_date: '03/12/2023',
			content: 'Practice self-containment so that your enthusiasm won’t be frittered away.',
			valid: true,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:460-465',
			Date: '2023-12-03T09:18:32Z',
			created_date: '03/12/2023',
			content:
				'The Qur’an forbids arguing with the People of the Book except in the best way and urges the Muslims to say: ‘We believe in what was revealed to us and in what was revealed to you; our God and your God are one [and the same]’ (29: 46). God addresses Muslims, Jews, and Christians with the following: ‘We have assigned a law and a path to each of you. If God had so willed, He would have made you one community, but He wanted to test you through that which He has given you, so race to do good: you will all return to God and He will make clear to you the matters you differed about’ (5: 48). The Qur’an allows Muslims to eat the food of the People of the Book and marry their women (5: 5). These are explicit statements which Muslims involved in interfaith dialogue rely upon.',
			valid: true,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:1506-1508',
			Date: '2023-12-10T01:04:39Z',
			created_date: '10/12/2023',
			content:
				'From this there has developed the general notion that the rate of return which the investor should aim for is more or less proportionate to the degree of risk he is ready to run. Our view is different. The rate of return sought should be dependent, rather, on the amount of intelligent effort the investor is willing and able to bring to bear on his task.',
			valid: true,
		},
		{
			title: 'Speed Reading with the Right Brain: Learn to Read Ideas Instead of Just Words (Right Brain Speed Reading)',
			author: 'David Butler',
			location: 'Loc:1573-1576',
			Date: '2024-01-14T15:37:15Z',
			created_date: '',
			content:
				'According to Nassim Taleb’s book Antifragile, the fastest growth of any type, whether physical or mental, occurs when an organism is prompted to overcompensate for significant stressors; it could be that longer practice sessions create this productive stress.',
			valid: false,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:3404-3407',
			Date: '2023-12-11T09:48:32Z',
			created_date: '11/12/2023',
			content:
				'The happiness of those who want to be popular depends on others; the happiness of those who seek pleasure fluctuates with moods outside their control; but the happiness of the wise grows out of their own free acts. —Marcus Aurelius',
			valid: true,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:5031-5032',
			Date: '2023-12-11T18:06:02Z',
			created_date: '11/12/2023',
			content: 'You can get ripped off easier by a dude with a pen than you can by a dude with a gun.',
			valid: true,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:6016-6018',
			Date: '2023-12-11T21:34:14Z',
			created_date: '11/12/2023',
			content:
				'It is easy in the world to live after the world’s opinion; it is easy in solitude to live after our own; but the great man is he who in the midst of the crowd keeps with perfect sweetness the independence of solitude.',
			valid: true,
		},
		{
			title: 'Art of Living: The Classical Manual on Virtue, Happiness, and Effectiveness',
			author: 'Nassim Nicholas Taleb',
			location: 'Loc:419-420',
			Date: '2023-12-12T16:08:01Z',
			created_date: '12/12/2023',
			content:
				'If you want to get an idea of a friend’s temperament, ethics, and personal elegance, you need to look at him under the tests of severe circumstances, not under the regular rosy glow of daily life.',
			valid: true,
		},
		{
			title: 'The Black Swan',
			author: 'Nassim Nicholas Taleb',
			location: 'Loc:775-778',
			Date: '2023-12-12T21:09:14Z',
			created_date: '12/12/2023',
			content:
				'The next time a Martian visits earth, try to explain to him why those who favor allowing the elimination of a fetus in the mother’s womb also oppose capital punishment. Or try to explain to him why those who accept abortion are supposed to be favorable to high taxation but against a strong military. Why do those who prefer sexual freedom need to be against individual economic liberty?',
			valid: true,
		},
		{
			title: 'The Black Swan',
			author: 'Nassim Nicholas Taleb',
			location: 'Loc:1198-1199',
			Date: '2023-12-12T22:43:48Z',
			created_date: '12/12/2023',
			content: 'I eventually stopped; we all become stingy and calculating when our wealth grows and we start taking money seriously.',
			valid: true,
		},
		{
			title: 'The Communist Manifesto',
			author: 'Karl Marx',
			location: 'Loc:473-473',
			Date: '2023-09-27T09:33:49Z',
			created_date: '',
			content: 'circulate in Germany belong to the domain of this foul and enervating',
			valid: false,
		},
		{
			title: 'The Black Swan',
			author: 'Nassim Nicholas Taleb',
			location: 'Loc:1499-1500',
			Date: '2023-12-14T22:43:56Z',
			created_date: '14/12/2023',
			content: 'We can get closer to the truth by negative instances, not by verification!',
			valid: true,
		},
		{
			title: 'The Black Swan',
			author: 'Nassim Nicholas Taleb',
			location: 'Loc:1525-1525',
			Date: '2023-12-14T22:46:54Z',
			created_date: '14/12/2023',
			content: 'But it remains the case that you know what is wrong with a lot more confidence than you know what is right.',
			valid: true,
		},
		{
			title: 'The Black Swan',
			author: 'Nassim Nicholas Taleb',
			location: 'Loc:1739-1742',
			Date: '2023-12-15T23:01:53Z',
			created_date: '15/12/2023',
			content:
				'We, members of the human variety of primates, have a hunger for rules because we need to reduce the dimension of matters so they can get into our heads. Or, rather, sadly, so we can squeeze them into our heads. The more random information is, the greater the dimensionality, and thus the more difficult to summarize. The more you summarize, the more order you put in, the less randomness. Hence the same condition that makes us simplify pushes us to think that the world is less random than it actually is.',
			valid: true,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:1316-1322',
			Date: '2023-12-24T18:36:55Z',
			created_date: '24/12/2023',
			content:
				'“If I may ask, in your view, how should life be lived? How does one make the most of this whole thing?” With a deep, slow, and tired exhale, the man said, “We don’t. Don’t you see? We are condemned by our awareness of the future and the perpetual slipperiness of every moment. We are stuck between the finite and the infinite, the now and the later, unable to ever reconcile this balance and know how to make the most of either. To make the most of now risks the future. To preserve the future risks never making the most of now. To be human is to be aware of and desire both, forced to live in between. And to live in between is to never touch either. Wisdom is accepting this condition. Live with a pessimism and lower your expectations, and the occasional good will emerge once in a while. That is what it means to live a good life.”',
			valid: true,
		},
		{
			title: 'The Courage to Be Disliked',
			author: 'Fumitake Koga, Ichiro Kishimi',
			location: 'Loc:3289-3290',
			Date: '2024-03-06T23:44:46Z',
			created_date: '',
			content: 'continues, “Whatever meaning life has must be assigned to it by the individual.”',
			valid: false,
		},
		{
			title: 'The Courage to Be Disliked',
			author: 'Fumitake Koga, Ichiro Kishimi',
			location: 'Loc:3343-3343',
			Date: '2024-03-06T23:48:06Z',
			created_date: '',
			content:
				'“Someone has to start. Other people might not be cooperative, but that is not connected to you. My advice is this: You should start. With no regard to whether others are cooperative or not.”',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:1334-1335',
			Date: '2023-12-24T18:38:30Z',
			created_date: '24/12/2023',
			content: 'There are countless ideas and sayings and so-called wisdoms that can justify nearly any way of living.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:101-101',
			Date: '2024-01-12T17:08:00Z',
			created_date: '',
			content: 'Istigkeit',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:1700-1701',
			Date: '2023-12-24T19:17:57Z',
			created_date: '24/12/2023',
			content: 'I see now that evil occurs not merely when one is intentionally doing wrong, but also when one believes they never could.',
			valid: true,
		},
		{
			title: 'Security Analysis',
			author: 'Benjamin Graham',
			location: 'Loc:1882-1885',
			Date: '2023-12-27T15:15:15Z',
			created_date: '27/12/2023',
			content:
				'There is a strong tendency in the stock market to value the management factor twice in its calculations. Stock prices reflect the large earnings which the good management has produced, plus a substantial increment for “good management” considered separately. This amounts to “counting the same trick twice,” and it proves a frequent cause of over-valuation.',
			valid: true,
		},
		{
			title: 'Security Analysis',
			author: 'Benjamin Graham',
			location: 'Loc:11223-11223',
			Date: '2024-01-12T10:07:45Z',
			created_date: '12/01/2024',
			content: 'There is very little altruism in finance.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:54-54',
			Date: '2024-01-12T17:00:43Z',
			created_date: '12/01/2024',
			content: 'By its very nature every embodied spirit is doomed to suffer and enjoy in solitude.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:151-152',
			Date: '2024-01-12T17:14:58Z',
			created_date: '12/01/2024',
			content:
				'The suggestion is that the function of the brain and nervous system and sense organs is in the main eliminative and not productive.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:215-216',
			Date: '2024-01-12T17:27:30Z',
			created_date: '12/01/2024',
			content: 'However expressive, symbols can never be the things they stand for.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:266-267',
			Date: '2024-01-12T19:46:57Z',
			created_date: '12/01/2024',
			content:
				'For the glory and the wonder of pure existence belong to another order, beyond the power of even the highest art to express.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:303-306',
			Date: '2024-01-12T19:52:16Z',
			created_date: '12/01/2024',
			content:
				'“This is how one ought to see,” I repeated yet again. And I might have added, “These are the sort of things one ought to look at.” Things without pretensions, satisfied to be merely themselves, sufficient in their Suchness, not acting a part, not trying, insanely, to go it alone, in isolation from the Dharma-Body, in Luciferian defiance of the grace of God.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:339-340',
			Date: '2024-01-12T19:56:34Z',
			created_date: '12/01/2024',
			content:
				'It gives access to contemplation—but to a contemplation that is incompatible with action and even with the will to action, the very thought of action.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:340-341',
			Date: '2024-01-12T19:56:57Z',
			created_date: '12/01/2024',
			content:
				'In the intervals between his revelations the mescalin taker is apt to feel that, though in one way everything is supremely as it should be, in another there is something wrong.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:354-354',
			Date: '2024-01-12T20:04:25Z',
			created_date: '12/01/2024',
			content: 'Half at least of all morality is negative and consists in keeping out of mischief.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:392-393',
			Date: '2024-01-12T20:09:30Z',
			created_date: '12/01/2024',
			content: 'What wonder, then, if human beings in their search for the divine have generally preferred to look within!',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:433-433',
			Date: '2024-01-12T20:14:57Z',
			created_date: '12/01/2024',
			content: 'The totality is present even in the broken pieces.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:477-479',
			Date: '2024-01-12T20:22:40Z',
			created_date: '12/01/2024',
			content:
				'This, I suddenly felt, was going too far. Too far, even though the going was into intenser beauty, deeper significance. The fear, as I analyze it in retrospect, was of being overwhelmed, of disintegrating under a pressure of reality greater than a mind, accustomed to living most of the time in a cosy world of symbols, could possibly bear.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:660-664',
			Date: '2024-01-12T21:15:42Z',
			created_date: '12/01/2024',
			content:
				'Literary or scientific, liberal or specialist, all our education is predominantly verbal and therefore fails to accomplish what it is supposed to do. Instead of transforming children into fully developed adults, it turns out students of the natural sciences who are completely unaware of Nature as the primary fact of experience, it inflicts upon the world students of the humanities who know nothing of humanity, their own or anyone else’s.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:697-701',
			Date: '2024-01-12T21:24:31Z',
			created_date: '12/01/2024',
			content:
				'Under a more realistic, a less exclusively verbal system of education than ours, every Angel (in Blake’s sense of that word) would be permitted as a sabbatical treat, would be urged and even, if necessary, compelled to take an occasional trip through some chemical Door in the Wall into the world of transcendental experience. If it terrified him, it would be unfortunate but probably salutary. If it brought him a brief but timeless illumination, so much the better. In either case the Angel might lose a little of the confident insolence sprouting from systematic reasoning and the consciousness of having read all the books.',
			valid: true,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:1185-1188',
			Date: '2024-01-13T11:37:10Z',
			created_date: '13/01/2024',
			content:
				'And then there is the horror of infinity. For the healthy visionary, the perception of the infinite in a finite particular is a revelation of divine immanence; for Renée, it was a revelation of what she calls “the System,” the vast cosmic mechanism which exists only to grind out guilt and punishment, solitude and unreality.**',
			valid: true,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:1731-1732',
			Date: '2023-12-24T19:21:33Z',
			created_date: '',
			content:
				'It is a great curse of all conscious beings to only have a choice in anything about themselves after it is mostly too late to have any choice in anything that matters.',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:284-285',
			Date: '2023-12-22T16:46:07Z',
			created_date: '',
			content: 'But the rose was always there, somewhere. And in this moment, Sarah finally saw it while she was in it.',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:369-370',
			Date: '2023-12-22T16:57:03Z',
			created_date: '',
			content: 'Everything becomes a different normal than the normal before, but it feels no less normal.',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:371-372',
			Date: '2023-12-22T16:57:24Z',
			created_date: '',
			content: 'Life can somehow be entirely different, and yet it will likely, at some point, feel mostly the same.',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:372-374',
			Date: '2023-12-22T16:57:57Z',
			created_date: '',
			content:
				'​I once read that after every seven years or so, every cell in the human body has completely regenerated, and the body becomes made entirely of a different collection of physical material. At which point, nothing that was once you is you anymore.',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:374-378',
			Date: '2023-12-22T16:59:24Z',
			created_date: '',
			content:
				'And across seven years, your life circumstances are likely different too, if not completely different. Meaning both your interior physical state and exterior circumstances are constantly changing completely, and yet you always feel mostly the same. At least in the sense that you still feel like you. It seems as if all processes of change in life are sifted through the same colander of self, and the only thing that is ever consistent on any level in any circumstance is that thing inside your head that continually identifies you with you, despite what’s going on around and through it.',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:380-382',
			Date: '2023-12-22T17:00:20Z',
			created_date: '',
			content:
				'The value and reformative nature of confinement is, at least for me, not necessarily to develop into a different person, but to properly face the strange, painful, difficult, and almost inexplicable person you might really be.',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:380-389',
			Date: '2023-12-22T17:02:20Z',
			created_date: '',
			content:
				'The value and reformative nature of confinement is, at least for me, not necessarily to develop into a different person, but to properly face the strange, painful, difficult, and almost inexplicable person you might really be. The person who isn’t even really a person, but the thing that lacks a complete and obvious person, but longs relentlessly for one. The truth of what you might be, that you went to great, massive efforts to otherwise avoid. And instead, you direct your efforts to learn how to live with this, rather than always lashing and flailing away from it. ​That’s where the real trouble came from, for me anyway. Eventually, if your strategy is to flail violently against yourself in an effort to overtake it, you’ll end up going to the end of the world, losing everything you have and love, just to ultimately end up being put here to confront the same fact that you knew all along: that you always go with you. ​Arguably, some level of solitude is inevitable in any life. But perhaps some level of deeper intentional solitude is necessary for a good one. At least for a period of time.',
			valid: false,
		},
		{
			title: 'The Hidden Story of Every Person',
			author: 'Robert Pantano',
			location: 'Loc:403-405',
			Date: '2023-12-22T17:04:12Z',
			created_date: '',
			content:
				'​And I was happy to see that after about three or four months in, the new guy chilled out. He stopped causing fights, stopped acting crazy. It’s mostly always the same. Eventually, everyone figures out how to be okay. Eventually, you don’t have a choice.',
			valid: false,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:1495-1497',
			Date: '2023-12-10T01:02:45Z',
			created_date: '',
			content: '“Blessed is he who expecteth nothing, for he shall enjoy everything.”',
			valid: false,
		},
		{
			title: 'The Doors of Perception',
			author: 'Aldous Huxley',
			location: 'Loc:1199-1202',
			Date: '2024-01-13T11:38:50Z',
			created_date: '13/01/2024',
			content:
				'When the visionary experience is terrible and the world is transfigured for the worse, individualization is intensified and the negative visionary finds himself associated with a body that seems to grow progressively more dense, more tightly packed, until he finds himself at last reduced to being the agonized consciousness of an inspissated lump of matter, no bigger than a stone that can be held between the hands.',
			valid: true,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:1961-1961',
			Date: '2023-12-10T07:31:43Z',
			created_date: '',
			content: 'dollar-cost averaging.',
			valid: false,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:2992-2995',
			Date: '2023-12-11T08:47:23Z',
			created_date: '',
			content:
				'Grab a copy of today’s Wall Street Journal, turn to the “Money & Investing” section, and take a look at the NYSE and NASDAQ Scorecards to find the day’s lists of stocks that have hit new lows for the past year—a quick and easy way to search for companies that might pass Graham’s net-working-capital tests. (Online, try http://quote. morningstar.com/highlow.html?msection=HighLow.)',
			valid: false,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:3169-3171',
			Date: '2023-12-11T09:12:43Z',
			created_date: '',
			content:
				'If he is the right kind of investor he will take added satisfaction from the thought that his operations are exactly opposite from those of the crowd.*',
			valid: false,
		},
		{
			title: 'One, No One, and One Hundred Thousand',
			author: 'Luigi Pirandello',
			location: 'Loc:125-126',
			Date: '2024-01-21T09:34:21Z',
			created_date: '21/01/2024',
			content: 'we are ready enough to note the faults of others, while all the time unconscious of our own.',
			valid: true,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:3462-3464',
			Date: '2023-12-11T12:58:29Z',
			created_date: '',
			content:
				'“The investor who permits himself to be stampeded or unduly worried by unjustified market declines in his holdings is perversely transforming his basic advantage into a basic disadvantage.”',
			valid: false,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:3519-3524',
			Date: '2023-12-11T13:05:16Z',
			created_date: '',
			content:
				'Groundbreaking new research in neuroscience shows that our brains are designed to perceive trends even where they might not exist. After an event occurs just two or three times in a row, regions of the human brain called the anterior cingulate and nucleus accumbens automatically anticipate that it will happen again. If it does repeat, a natural chemical called dopamine is released, flooding your brain with a soft euphoria. Thus, if a stock goes up a few times in a row, you reflexively expect it to keep going—and your brain chemistry changes as the stock rises, giving you a “natural high.” You effectively',
			valid: false,
		},
		{
			title: 'One, No One, and One Hundred Thousand',
			author: 'Luigi Pirandello',
			location: 'Loc:199-200',
			Date: '2024-01-21T09:41:39Z',
			created_date: '21/01/2024',
			content: 'Solitude is never where you are; it is always where you are not, and is only possible with a stranger present;',
			valid: true,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:5615-5615',
			Date: '2023-12-11T19:54:44Z',
			created_date: '',
			content: 'Vanguard Small-Cap Index',
			valid: false,
		},
		{
			title: 'One, No One, and One Hundred Thousand',
			author: 'Luigi Pirandello',
			location: 'Loc:203-204',
			Date: '2024-01-21T09:43:05Z',
			created_date: '21/01/2024',
			content:
				'This was the way in which I wanted to be alone. Without myself. I mean to say, without that self which I already knew, or which I thought I knew.',
			valid: true,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:6017',
			Date: '2023-12-11T21:34:36Z',
			created_date: '',
			content: 'BEAUTIFUL',
			valid: false,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:7449-7451',
			Date: '2023-12-12T11:04:28Z',
			created_date: '',
			content: 'The most dangerous untruths are truths slightly distorted. —G. C. Lichtenberg',
			valid: false,
		},
		{
			title: 'The Intelligent Investor',
			author: 'Benjamin Graham',
			location: 'Loc:7915-7918',
			Date: '2023-12-12T11:48:26Z',
			created_date: '',
			content:
				'Suppose you act as though God is and [you] lead a life of virtue and abstinence, when in fact there is no god. You will have passed up some goodies in life, but there will be rewards as well. Now suppose you act as though God is not and spend a life of sin, selfishness, and lust when in fact God is. You may have had fun and thrills during the relatively brief duration of your lifetime, but when the day of judgment rolls around you are in big trouble.',
			valid: false,
		},
		{
			title: 'The Little Prince',
			author: 'Antoine de Saint Exupéry',
			location: 'Loc:205-205',
			Date: '2023-10-22T10:22:10Z',
			created_date: '',
			content: 'It is such a secret place, the land of tears.',
			valid: false,
		},
		{
			title: 'The Little Prince',
			author: 'Antoine de Saint Exupéry',
			location: 'Loc:233-236',
			Date: '2023-10-22T10:26:34Z',
			created_date: '',
			content:
				'I ought not to have listened to her," he confided to me one day. "One never ought to listen to the flowers. One should simply look at them and breathe their fragrance. Mine perfumed all my planet. But I did not know how to take pleasure in all her grace. This tale of claws, which disturbed me so much, should only have filled my heart with tenderness and pity.',
			valid: false,
		},
		{
			title: 'The Little Prince',
			author: 'Antoine de Saint Exupéry',
			location: 'Loc:239-239',
			Date: '2023-10-22T10:27:26Z',
			created_date: '',
			content: 'Flowers are so inconsistent! But I was too young to know how to love her . .',
			valid: false,
		},
		{
			title: 'The Little Prince',
			author: 'Antoine de Saint Exupéry',
			location: 'Loc:288-289',
			Date: '2023-10-22T10:33:12Z',
			created_date: '',
			content:
				'Accepted authority rests first of all on reason. If you ordered your people to go and throw themselves into the sea, they would rise up in revolution. I have the right to require obedience because my orders are reasonable.',
			valid: false,
		},
		{
			title: 'The Little Prince',
			author: 'Antoine de Saint Exupéry',
			location: 'Loc:519-520',
			Date: '2023-10-22T10:58:47Z',
			created_date: '',
			content:
				'But if you tame me, it will be as if the sun came to shine on my life. I shall know the sound of a step that will be different from all the others.',
			valid: false,
		},
		{
			title: 'One, No One, and One Hundred Thousand',
			author: 'Luigi Pirandello',
			location: 'Loc:1314-1318',
			Date: '2024-01-21T12:12:39Z',
			created_date: '21/01/2024',
			content:
				'“Ah, yes! Ah, yes! What sort of reality can that be which the majority of mankind succeed in setting up inside themselves? Wretched, slippery, uncertain. And the domineering ones, mark you, profit by it! Or better, they are under the illusion of being in a position to profit by it, by causing to be submissively accepted that meaning and that value which they attribute to themselves, to others, and to things, in order that all may see and hear, think and speak after their manner.”',
			valid: true,
		},
		{
			title: 'One, No One, and One Hundred Thousand',
			author: 'Luigi Pirandello',
			location: 'Loc:1583-1584',
			Date: '2024-01-21T21:47:37Z',
			created_date: '21/01/2024',
			content:
				'“Why is it, when one thinks of killing himself, that he imagines himself as being dead, no longer to himself, but to others?”',
			valid: true,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:342-343',
			Date: '2024-01-27T10:43:25Z',
			created_date: '27/01/2024',
			content:
				'With the arrival of humans, it has been said, the universe has suddenly become conscious of itself. This, truly, is the greatest mystery of all.',
			valid: true,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:229-233',
			Date: '2023-12-03T08:32:41Z',
			created_date: '',
			content:
				'Settled in Yathrib, Muhammad made a pact of mutual solidarity between the immigrants (muhajirun) and the Muslims of Yathrib, known as the ansar—helpers. This alliance, based not on tribal but on religious solidarity, was a departure from previous social norms. Muhammad also made a larger pact between all the tribes of Yathrib, that they would all support one another in defending the city against attack. Each tribe would be equal under this arrangement, including the Jews, and free to practise their own religions.',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:305-306',
			Date: '2023-12-03T08:44:04Z',
			created_date: '',
			content: 'Muslims should be made and safely stored with him.9 About twelve years',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:331-333',
			Date: '2023-12-03T08:48:27Z',
			created_date: '',
			content:
				'The Qur’an stresses that all these prophets preached the same message and that the Qur’an was sent to confirm the earlier messages. It states that Muslims should believe in all of them without making any distinction between them (2: 285).',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:371-372',
			Date: '2023-12-03T09:01:44Z',
			created_date: '',
			content: '‘There is no compulsion in religion: true guidance has become distinct from error’ (2: 256)',
			valid: false,
		},
		{
			title: 'The Courage to Be Disliked',
			author: 'Fumitake Koga, Ichiro Kishimi',
			location: 'Loc:734-736',
			Date: '2024-03-03T11:22:16Z',
			created_date: '03/03/2024',
			content:
				'Oh, but being alone isn’t what makes you feel lonely. Loneliness is having other people and society and community around you, and having a deep sense of being excluded from them. To feel lonely, we need other people. That is to say, it is only in social contexts that a person becomes an “individual.”',
			valid: true,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:907-910',
			Date: '2023-12-03T10:08:42Z',
			created_date: '',
			content:
				'6As for those who disbelieve, it makes no difference whether you warn them or not: they will not believe. 7God has sealed their hearts and their ears, and their eyes are covered. They will have great torment.c',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:991-993',
			Date: '2023-12-03T10:23:57Z',
			created_date: '',
			content:
				'62The [Muslim] believers, the Jews, the Christians, and the Sabiansa—all those who believe in God and the Last Day and do good—will have their rewards with their Lord.',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:1079-1085',
			Date: '2023-12-05T11:01:38Z',
			created_date: '',
			content:
				'111They also say, ‘No one will enter Paradise unless he is a Jew or a Christian.’ This is their own wishful thinking. [Prophet], say, ‘Produce your evidence, if you are telling the truth.’ 112In fact, any who direct themselvesc wholly to God and do good will have their reward with their Lord: no fear for them, nor will they grieve. 113The Jews say, ‘The Christians have no ground whatsoever to stand on,’ and the Christians say, ‘The Jews have no ground whatsoever to stand on,’ though they both read the Scripture, and those who have no knowledge say the same; God will judge between them on the Day of Resurrection concerning their differences. 114Who could be',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:1151-1153',
			Date: '2023-12-05T11:11:56Z',
			created_date: '',
			content:
				'148Each community has its own direction to which it turns: race to do good deeds and wherever you are, God will bring you together.b God has power to do everything.',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:1183-1183',
			Date: '2023-12-05T11:15:52Z',
			created_date: '',
			content: '‘If only we had one last chance, we would disown them as they now disown us.’',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:1187-1187',
			Date: '2023-12-05T11:17:28Z',
			created_date: '',
			content: '‘Follow the message that God has sent down,’',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:1191-1194',
			Date: '2023-12-05T11:18:49Z',
			created_date: '',
			content:
				'173He has only forbidden you carrion, blood, pig’s meat, and animals over which any name other than God’s has been invoked. But if anyone is forced to eat such things by hunger, rather than desire or excess, he commits no sin: God is most merciful and forgiving.',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:1198-1203',
			Date: '2023-12-05T11:20:00Z',
			created_date: '',
			content:
				'177Goodness does not consist in turning your face towards East or West. The truly good are those who believe in God and the Last Day, in the angels, the Scripture, and the prophets; who give away some of their wealth, however much they cherish it, to their relatives, to orphans, the needy, travellers and beggars, and to liberate those in bondage; those who keep up the prayer and pay the prescribed alms; who keep pledges whenever they make them; who are steadfast in misfortune, adversity, and times of danger. These are the ones who are true, and it is they who are aware of God.',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:1232-1233',
			Date: '2023-12-05T11:25:00Z',
			created_date: '',
			content:
				'190Fight in God’s cause against those who fight you, but do not overstep the limits:c God does not love those who overstep the limits.',
			valid: false,
		},
		{
			title: "The Qur'an",
			author: 'M. A. S. Abdel Haleem',
			location: 'Loc:1299-1299',
			Date: '2023-12-05T21:03:34Z',
			created_date: '',
			content: 'idolatresses',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:4136-4136',
			Date: '2024-02-25T15:27:18Z',
			created_date: '',
			content: 'Fred Hoyle’s theory of the oscillating universe.)',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:4384-4385',
			Date: '2024-02-25T15:58:31Z',
			created_date: '',
			content:
				'The person who concocted this syndrome, whoever he or she is, is brilliant, for any attempt by the patient to challenge or protest the diagnosis can itself be construed as evidence for its validity!',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:262-262',
			Date: '2024-01-25T22:58:25Z',
			created_date: '',
			content: 'an age when the hard-won values of the Enlightenment are sadly in decline.',
			valid: false,
		},
		{
			title: 'The Courage to Be Disliked',
			author: 'Fumitake Koga, Ichiro Kishimi',
			location: 'Loc:1449-1449',
			Date: '2024-03-03T13:46:46Z',
			created_date: '03/03/2024',
			content: '“Money is coined freedom.”',
			valid: true,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:551-552',
			Date: '2024-01-27T11:14:43Z',
			created_date: '',
			content: 'servo-control loop.',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:1334-1334',
			Date: '2024-02-04T09:17:57Z',
			created_date: '',
			content: 'Oedipus complex.',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:2134-2134',
			Date: '2024-02-20T19:56:23Z',
			created_date: '',
			content: 'theory of mind,',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:2258-2259',
			Date: '2024-02-21T21:12:25Z',
			created_date: '',
			content: 'The only thing separating your consciousnesses from another’s might be your skin!',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:2529-2529',
			Date: '2024-02-24T15:20:31Z',
			created_date: '',
			content: '(magnetoencephalography),',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:2927-2927',
			Date: '2024-02-25T11:52:10Z',
			created_date: '',
			content: 'emergence.',
			valid: false,
		},
		{
			title: 'The Tell-Tale Brain',
			author: 'V. S. Ramachandran',
			location: 'Loc:3008-3009',
			Date: '2024-02-25T12:01:18Z',
			created_date: '',
			content: 'As the amino acid starts accumulating in the child’s brain, he becomes profoundly retarded.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:190-194',
			Date: '2023-09-30T00:49:59Z',
			created_date: '',
			content:
				'First, you must learn desire. Hold its fruit in your hands. Unmarry it from the hunger to be held, to be wanted, to be called from the streets like the family dog. You are not a good girl. You are not somebody’s otherness. This is not a dress rehearsal before a better kind of life. Pick up your heavy burdens and leave them at the gate. I will hold the door for you.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:205-209',
			Date: '2023-09-30T00:51:04Z',
			created_date: '',
			content:
				'Unless you count your grandmother’s cake, hand mixed while she waits for the sound of your breath at the door. Or if you consider the taste of the sea, arms raised while you enter, salt at your lips. Or maybe you’ve forgotten the taste of a lover, your mouth on his skin. I ask— have you ever tasted the cool swill of freedom? The consuming rush of a quiet, radical love.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:268-272',
			Date: '2023-09-30T00:55:38Z',
			created_date: '',
			content:
				'When I take my evening walk, I unlock rooms I never meant to close, which is one way to write a book. Another is to peel an orange or take a shower or fall in love with someone dangerous. At any given moment there is someone getting what they always wanted. I know no quicker way to ruin a day than to dwell on this.',
			valid: false,
		},
		{
			title: 'The Courage to Be Disliked',
			author: 'Fumitake Koga, Ichiro Kishimi',
			location: 'Loc:1874-1875',
			Date: '2024-03-04T23:51:29Z',
			created_date: '04/03/2024',
			content: '“freedom is being disliked by other people.”',
			valid: true,
		},
		{
			title: 'The Courage to Be Disliked',
			author: 'Fumitake Koga, Ichiro Kishimi',
			location: 'Loc:2673-2674',
			Date: '2024-03-06T21:54:13Z',
			created_date: '06/03/2024',
			content:
				'“God grant me the serenity to accept the things I cannot change; courage to change the things I can; and wisdom to know the difference.”',
			valid: true,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:353-353',
			Date: '2023-09-30T12:25:06Z',
			created_date: '',
			content: 'stands at the feet of grief and wanting and does not turn away.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:353-354',
			Date: '2023-09-30T12:25:19Z',
			created_date: '',
			content: 'What kind of man becomes a father. A lasting place. A steady ship inside a tireless storm.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:433-433',
			Date: '2023-09-30T12:34:27Z',
			created_date: '',
			content: 'If you listen, I will tell you all the ways I have betrayed you.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:485-486',
			Date: '2023-09-30T12:40:29Z',
			created_date: '',
			content: 'Nothing in this world can prepare you for this love’s suffering.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:595-599',
			Date: '2023-09-30T15:00:26Z',
			created_date: '',
			content:
				'My son asks me to stop saying I love you at the bus stop. No kissing either; even a side-hug is vaguely forbidden. Against my better judgment, I remind him from where he came. I say—I carried you in my womb. I held you every day until you were born. But he only shrugs. Says, I didn’t ask you to.',
			valid: false,
		},
		{
			title: 'Cognitive Neuroscience:  A Very Short Introduction',
			author: 'Richard E Passingham',
			location: 'Loc:1677-1678',
			Date: '2024-03-10T18:07:57Z',
			created_date: '10/03/2024',
			content: 'However, the fact that something is widely believed often means that it is incorrect.',
			valid: true,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:619-620',
			Date: '2023-09-30T15:03:48Z',
			created_date: '',
			content: 'you’re stronger that you know.',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:630-631',
			Date: '2023-09-30T15:06:00Z',
			created_date: '',
			content: 'Here is the beginning, the earth and its fragility heaped on your chest while you lean over to drink,',
			valid: false,
		},
		{
			title: 'What Kind of Woman',
			author: 'Kate Baer',
			location: 'Loc:669-671',
			Date: '2023-09-30T15:09:30Z',
			created_date: '',
			content:
				'Even when death is coming, even when the filth rises in the back of your throat— this is not the worst of it. And if it is? Listen for the catbird calling. No matter the wreckage, they still sing for you.',
			valid: false,
		},
		{
			title: 'Your Brain Is a Time Machine',
			author: 'Buonomano, Dean [Buonomano, Dean]',
			location: 'Loc:3257-3259',
			Date: '2024-03-10T10:14:22Z',
			created_date: '10/03/2024',
			content:
				'In peering into the future our ancestors must have foreseen more than they were prepared to cope with: their own inevitable death. This disturbing vision perhaps led them even farther into the future, and to the invention of extreme mental time travel: they envisioned an afterlife.',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:1314-1315',
			Date: '2024-03-27T01:39:07Z',
			created_date: '',
			content: 'the final reward of the dead is to die no more!',
			valid: false,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:1172-1173',
			Date: '2024-03-26T22:33:21Z',
			created_date: '26/03/2024',
			content:
				'“Sometimes,” Nietzsche responded, “teachers must be hard. People must be given a hard message because life is hard, and dying is hard.”',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:2375-2376',
			Date: '2024-03-27T12:33:07Z',
			created_date: '27/03/2024',
			content:
				'Ordinarily, leavetaking is accompanied by denials of the permanence of the event: people say, ‘Auf Wiedersehen’—until we meet again. They are quick to plan for reunions and then, even more quickly, forget their resolutions.',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:2410-2410',
			Date: '2024-03-27T12:36:39Z',
			created_date: '27/03/2024',
			content: 'Despair is the price one pays for self-awareness.',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:2613-2615',
			Date: '2024-03-27T12:59:50Z',
			created_date: '27/03/2024',
			content:
				'“When you’re in love with a woman, you want her to think well of you in all ways. Naturally, you’re going to keep things hidden about yourself—things that might present you in a bad light. Your sexual lusts, for example.”',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:3038-3039',
			Date: '2024-03-27T16:18:57Z',
			created_date: '',
			content:
				'‘Sensuality is a bitch that nips at our heels! And how nicely this bitch knows how to beg for a piece of spirit when denied a piece of meat.’',
			valid: false,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:2628-2628',
			Date: '2024-03-27T13:03:05Z',
			created_date: '27/03/2024',
			content: 'primum non nocere—',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:3059-3060',
			Date: '2024-03-27T16:21:33Z',
			created_date: '27/03/2024',
			content:
				'I must first teach you to walk, and the first step in learning to walk is to understand that he who does not obey himself is ruled by others.',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:3060-3061',
			Date: '2024-03-27T16:21:51Z',
			created_date: '27/03/2024',
			content: 'It is easier, far easier, to obey another than to command oneself.”',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:3090-3091',
			Date: '2024-03-27T16:24:35Z',
			created_date: '27/03/2024',
			content: 'If you kill God, you must also leave the shelter of the temple.”',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:3916-3917',
			Date: '2024-03-27T21:45:30Z',
			created_date: '27/03/2024',
			content: '“I’ve always believed, Josef, that we are more in love with desire than with the desired!”',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:4175-4176',
			Date: '2024-03-27T23:17:01Z',
			created_date: '',
			content: 'The lover must put out his own eyes, must forsake truth. And, for me, an untrue life is a living death!”',
			valid: false,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:4361-4362',
			Date: '2024-03-27T23:45:09Z',
			created_date: '',
			content:
				'Marriage should be no prison, but a garden in which something higher is cultivated. Perhaps the only way to save your marriage is to give it up.”',
			valid: false,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:4799-4800',
			Date: '2024-03-28T00:24:19Z',
			created_date: '',
			content:
				'I blamed Mathilde for not allowing me to face what I was really unwilling to face! I wonder how many other husbands do that to their wives?”',
			valid: false,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:4883-4884',
			Date: '2024-03-28T00:31:20Z',
			created_date: '',
			content: 'first to will that which is necessary and then to love that which is willed.”',
			valid: false,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:3922-3923',
			Date: '2024-03-27T21:46:08Z',
			created_date: '27/03/2024',
			content: 'I hate others who rob me of my solitude and yet do not truly offer me company.”',
			valid: true,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:5206-5207',
			Date: '2024-03-28T00:58:00Z',
			created_date: '28/03/2024',
			content: '“Isolation exists only in isolation. Once shared, it evaporates.”',
			valid: true,
		},
		{
			title: 'Your Brain Is a Time Machine',
			author: 'Buonomano, Dean [Buonomano, Dean]',
			location: 'Loc:1684-1684',
			Date: '2024-03-09T17:49:51Z',
			created_date: '',
			content: 'We do not yet understand how the circuits within our cortex solve the problem of chaos.',
			valid: false,
		},
		{
			title: 'Your Brain Is a Time Machine',
			author: 'Buonomano, Dean [Buonomano, Dean]',
			location: 'Loc:2986-2988',
			Date: '2024-03-10T09:36:10Z',
			created_date: '',
			content:
				'There is a theory which states that if ever anyone discovers exactly what the Universe is for and why it is here, it will instantly disappear and be replaced by something even more bizarre and inexplicable. There is another which states that this has already happened.',
			valid: false,
		},
		{
			title: 'When Nietzsche Wept',
			author: 'Yalom, Irvin D.',
			location: 'Loc:5211-5213',
			Date: '2024-03-28T00:58:48Z',
			created_date: '28/03/2024',
			content:
				'“You yourself taught me that we are each composed of many parts, each clamoring for expression. We can be held responsible only for the final compromise, not for the wayward impulses of each of the parts.',
			valid: true,
		},
	];
}
