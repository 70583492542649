<app-basic-page>
    <h1 title>tennis #1</h1>

    <div content>
        I started learning tennis in July '24.
        I thought it would fun to start learning something new; however, I didn't
        realise how much it would teach me about myself.

        <br/>
        <br/>

        See, an undeniably complex part of tennis is the mental challenge that comes from competing
        directly with another person. Now, I've competed previously in individual sports (boxing)
        where I could easily handle the pressure of playing against another person. The secret to
        boxing is that adrenaline is one hell of a drug. It pushes your focus, your aggression, and
        with a bit of emotional control, you can leverage all that energy into fighting smarter and
        harder.

        <br/>
        <br/>

        However, with tennis, you don't have to fear getting punched hard in the face. A tiny ball
        is flying towards you and you have to hit it the right way to get it across the court. If
        you miss the ball, then you lose a point but nothing damaging happens.

        <br/>
        <br/>

        But like all individual sports, it's only you and your opponent on that court. You have
        nothing else to blame when losing. Every lost ball is effectively your fault and how you're
        managing the game. And that right there is the ultimate mental challenge.

        <br/>
        <br/>

        The moment you start losing is the same moment you start doubting what you're doing wrong.
        Your partner seems to be doing all the right things.

        <br/>
        <br/>

        This makes you tense your muscles in the fear of making mistakes and start playing weaker
        balls. Your partner is riding on the wins and picking up confidence so they start playing on
        their limit. You take less risks, play soft, and safe. They take more risks, play on their
        limit, and control your game.

        <br/>
        <br/>

        Your partner will say or do things that might anger you, and you will get into your head,
        pushing yourself to do better, but losing focus on the principles. You focus on the details
        of things but not let your body do what's right. Instead of focusing on intensity and
        keeping your body & mind in the game, you start thinking about how to hit the ball, your
        wrist, your shoulder, your feet, and then another mistake happens. Sometimes you're still
        thinking about that one thing your partner said. Then loss after loss, you're in this
        inevitable cycle of compression. But how can you manage this?

        <br/>
        <br/>

        Life is somewhat similar to this. The more you are ahead, the more margin of error you have,
        and the more risks you can take. When you do inevitably fall, your margin allows you to
        continue on forward without feeling immense pressure. When your risks pay off, then your
        margin grows significantly. The opposite is true when your delta is negative.

        <br/>
        <br/>

        In this sense, tennis thought me that only the current move matters. Attack every move with
        intensity, confidence, and relentlessness.
    </div>

    <img class="tennis__image" image src="/assets/images/tennis-1.jpeg" alt="tennis workout summary"/>
    <figcaption caption>workout summary - hh, '24</figcaption>
</app-basic-page>
