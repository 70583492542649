import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-sound-on',
	templateUrl: './sound-on.component.html',
	styleUrls: ['./sound-on.component.scss'],
	animations: [
		trigger('slideInFade', [
			state(
				'in',
				style({
					opacity: 1,
					transform: 'translateX(0)',
				})
			),
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(-30%)',
				}),
				animate('0.5s ease'),
			]),
			transition(':leave', [
				animate(
					'0.5s ease',
					style({
						opacity: 0,
						transform: 'translateX(30%)',
					})
				),
			]),
		]),
	],
})
export class SoundOnComponent implements OnInit {
	isNextVisible = false;

	@Output() nextPress = new EventEmitter<void>();

	showNextButton: boolean;

	constructor() {
		this.showNextButton = false;
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.isNextVisible = true;
		}, 1200);
	}

	next(): void {
		this.nextPress.emit();
	}
}
