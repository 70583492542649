import { Component, HostListener, OnInit } from '@angular/core';

@Component({
	selector: 'app-blonde-card',
	templateUrl: './blonde-card.component.html',
	styleUrls: ['./blonde-card.component.scss'],
})
export class BlondeCardComponent implements OnInit {
	hovering = false;

	constructor() {}

	@HostListener('mouseenter')
	onMouseEnter() {
		this.hovering = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.hovering = false;
	}

	ngOnInit(): void {}
}
