import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoundOnComponent } from './sound-on.component';
import { UiModule } from '../ui/ui.module';
import { BtnModule } from '../btn/btn.module';

@NgModule({
	declarations: [SoundOnComponent],
	exports: [SoundOnComponent],
	imports: [CommonModule, UiModule, BtnModule],
})
export class SoundOnModule {}
