<button class="about__back" appBtn buttonType="icon" (click)="onNavigateHome()">
	<i class="mi-arrow-left"></i>
</button>

<div class="about__introduction">
	<div class="about__introduction__text">
		<div appTypography textStyle="title">
			about.
		</div>

		<div appTypography textStyle="subtitle">
			I'm Otmane, you can all me OT.
		</div>


		<div class="about__introduction__text__details" appTypography textStyle="body-regular">
			I'm based in beautiful Hamburg, Germany.
			<br />
			I enjoy exploring how the
			intersections between engineering and other disciplines can
			open up fresh perspectives in my mind. You can explore this space to get to know me
			better.
		</div>
	</div>

	<img class="about__introduction__image" src="/assets/images/about.png" alt="picture of me sitting outside">
</div>

<span class="about__triangle">
		<svg width="50" height="50" viewBox="0 0 65 57" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path class="about__triangle__path" d="M1.7561 55.25L32.5 2L63.2439 55.25H1.7561Z"
					stroke-width="2" />
	</svg>
</span>
