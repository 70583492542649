<span (click)="onClick()">
	<div class="pink-white-card" appTypography textStyle="header-1" [class.active]="active">
		<span *ngIf="!active">
			<span class="base-text"> 3. </span>
			<span class="hover-text">
				Mother <br />
				Earth.
			</span>
		</span>
		<span *ngIf="active">
			<span class="pink-white-card__expanded">
				<div>
					<app-lyric-band> You'd kneel down to the dry land </app-lyric-band>
					<app-lyric-band>Kiss the earth that birthed you</app-lyric-band>
					<app-lyric-band>Gave you tools just to stay alive</app-lyric-band>
					<app-lyric-band>And make it out when the sun is ruined</app-lyric-band>
				</div>
				<div>
					<div appTypography>Pink + White</div>
				</div>
			</span>
		</span>
	</div>
</span>
