import { ComponentRef, Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { SpinnerOverlayComponent } from '../spinner-overlay/spinner-overlay.component';

@Injectable({
	providedIn: 'root',
})
export class SpinnerService {
	private overlayRef: OverlayRef | undefined;

	constructor(private readonly overlay: Overlay) {}

	show(): void {
		if (!this.overlayRef) {
			this.overlayRef = this.overlay.create();
		}
		const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
		this.overlayRef.attach(spinnerOverlayPortal);
	}

	hide(): void {
		if (!!this.overlayRef) {
			this.overlayRef?.detach();
		}
	}
}
