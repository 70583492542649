<app-basic-page>
	<h1 title>Suffering and Purpose</h1>

	<div content>
		Life, a continuous process of existence and survival for all living beings on Earth, is fundamentally underscored by suffering. This
		suffering embodies unpleasant, painful, or distressing experiences.

		<br /><br />

		Our motivation draws from suffering, and paradoxically, we find purpose in enduring it. The inherent nature of life, wherein survival
		becomes critical, has significantly affected our conscious and unconscious minds since the beginning of existence. This ongoing struggle
		has predominantly occupied our minds and initiated extensive evolutionary cycles, leading to the development of the human brain and
		consequently, consciousness. In essence, the face of existential threats and other stressors instigating suffering have been
		instrumental in human evolution.

		<br /><br />

		Contrastingly, human life quality has seen a marked improvement since our early survival stages. Advancements in society, education, and
		technology have rendered suffering less frequent and even avoidable for many. We have learned to derive pleasure with no risks involved.
		This is evident in the prevalence of immediate gratification provided by social media, video games, alcohol, fast food, and the like.

		<br /><br />

		In an era replete with instant rewards, humans have remarkably lost their ability to find purpose in their existence. Abstinence,
		considered virtue in the past, is now scorned upon. The socio-political terrain continues to shift, making suffering more tolerable, and
		even a cause for celebration. In so doing, it reverses centuries of evolutionary refinement and selection. A glaring example is how an
		overweight model is encouraged to perceive their overweight condition, not as a marker of suffering, but as a form of pleasure.

		<br /><br />

		Such agendas, largely propelled by social reformists, face no immediate backlash due to capitalist inclinations. Capitalist societies
		often prioritize immediate gain rather than long-term benefits. For instance, it's more profitable to sell a swimsuit to an overweight
		customer now, despite the increased production costs, rather than waiting five years for the customer to lose weight.

		<br /><br />

		Neglecting radical leftist constructs, the most rewarding and meaningful human experience is still the one filled with suffering and
		overcoming it. Despite our ancestors having endured difficult lives, the contemporary society faces an even greater challenge – choice.
		Our ancestors had no choice but to endure suffering in the face of environmental stressors, viciously courting survival. But, in our
		era, we find ourselves at a crossroads – to suffer or not to, to merely exist or truly live. These choices contradict parts of our
		biology and environment, pushing us to consciously choose the path of maximum resistance, embracing the suffering that comes with
		delayed gratification.

		<br /><br />

		This is why religion is effective at providing purpose and meaning to human life. In most religions, like Judaism, Islam, Hinduism, and
		non-Catholic Christianity, abstinence is extolled as virtue and suffering is seen as a part of life, a virtue itself, and a positive
		aspect in God's eyes. A quote from the Quran encapsulates this sentiment,

		<br /><br />

		“And We will surely test you with something of fear and hunger and a loss of wealth and lives and fruits, but give good tidings to the
		patient…Who, when disaster strikes them, say, ‘Indeed we belong to Allah, and indeed to Him we will return.." (Al Baqarah, 155-156)

		<br /><br />

		Hence, religion ascribes value to pain, leading its followers to find purpose in the experience of suffering through the belief systems
		crafted by their faith. They impose self-control over their desires in adherence to religious rules, finding more meaning in their
		decisions and self-worth. When the burden becomes too heavy for an individual, religion provides solace by allowing believers to place
		their hardships with a higher power, accept their suffering, and continue to live forward-focused lives.

		<br /><br />

		Despite diverse belief systems, non-believers, atheists, or agnostics follow a similar pattern. Take entrepreneurs, for instance. They
		understand their risk exposure, demonstrate extensive skin-in-the-game, resist pain with sheer dedication, and manage to overcome the
		struggles of founding a company and participating in the vast economy.

		<br /><br />

		Though life may be easy, an easy life isn't necessarily a fulfilling one. A life punctuated with suffering, resistance, and pain may be
		hard, but undeniably, it holds more gratification.
	</div>

	<img class="suffering__image" image src="/assets/images/goat.png" alt="goat-picture"/>
	<figcaption caption>Pops's goat - Marrakech, 2023</figcaption>
</app-basic-page>
