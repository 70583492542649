import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivatePageComponent } from './pages/private-page/private-page.component';
import { YearsPageComponent } from './pages/years-page/years-page.component';
import { SoundOnPageComponent } from './pages/sound-on-page/sound-on-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { FrankOceanPageComponent } from './pages/frank-ocean-page/frank-ocean-page.component';
import { PinkWhitePageComponent } from './modules/frank-ocean/pink-white/pink-white-page/pink-white-page.component';
import { PrivatePagesGuardService } from './service/private-pages-guard.service';
import { SufferingComponent } from './pages/suffering/suffering.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HardworkComponent } from './pages/hardwork/hardwork.component';
import { UnconventionalComponent } from './pages/unconventional/unconventional.component';
import { BookClippingsComponent } from './pages/book-clippings/book-clippings.component';
import { DecisionsProbabilityComponent } from './pages/decisions-probability/decisions-probability.component';
import { WritingsPageComponent } from './pages/writings-page/writings-page.component';
import { CheapTalkComponent } from './pages/cheap-talk/cheap-talk.component';
import { TennisComponent } from './pages/tennis/tennis.component';
import { AboutComponent } from './pages/about/about.component';

const routes: Routes = [
	{ path: '', component: PrivatePageComponent, pathMatch: 'full' },
	{ path: 'sound-on', component: SoundOnPageComponent, canActivate: [PrivatePagesGuardService] },
	{ path: '2years', component: YearsPageComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'frank', component: FrankOceanPageComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'track-3', component: PinkWhitePageComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'suffering', component: SufferingComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'hard-work', component: HardworkComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'unconventional-path', component: UnconventionalComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'master', component: HomePageComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'clippings', component: BookClippingsComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'writings', component: WritingsPageComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'decisions', component: DecisionsProbabilityComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'talk-is-cheap', component: CheapTalkComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'tennis-1', component: TennisComponent, canActivate: [PrivatePagesGuardService] },
	{ path: 'about', component: AboutComponent },
	{
		path: '**',
		component: NotFoundPageComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
