import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../data/general.data';
import * as fromPrivate from 'src/app/store/private';
import { SpinnerService } from '../../modules/spinner/service/spinner.service';
import { IEntryHashMap, IEntryHashState } from '../../store/private/private.model';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5';
import { delay } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../modules/ui/ui.module';
import { BtnModule } from '../../modules/btn/btn.module';

@Component({
	selector: 'app-private-page',
	templateUrl: './private-page.component.html',
	styleUrls: ['./private-page.component.scss'],
	imports: [CommonModule, UiModule, ReactiveFormsModule, FormsModule, BtnModule],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivatePageComponent implements OnInit {
	constructor(private readonly store: Store<IAppState>, private readonly spinnerService: SpinnerService, private readonly router: Router) {
		this.password = new FormControl('', [
			Validators.required,
			Validators.minLength(this.minLength),
			Validators.nullValidator,
			PrivatePageComponent.strong,
		]);
	}

	password: FormControl<string | null>;
	isTyping: boolean = false;
	capsOn = false;
	isCorrect = true;
	minLength: number = 5;
	entryHashes: IEntryHashMap | undefined;
	_md5: Md5 | undefined;

	static strong(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value === null || control.value === '') {
				return { strong: false };
			}
			const hasUpper = /[A-Z]/.test(control.value);
			return { strong: !hasUpper };
		};
	}

	ngOnInit(): void {
		this._md5 = new Md5();
		this.clearLocalStorage();
		this.store.select(fromPrivate.selectEntryHashState).subscribe((entryHashState: IEntryHashState) => {
			if (entryHashState.isLoading) {
				this.spinnerService.show();
			}
			if (entryHashState.isLoaded) {
				this.spinnerService.hide();
			}
		});
		this.store.select(fromPrivate.selectEntryHashesMap).subscribe((results: IEntryHashMap) => {
			this.entryHashes = results;
		});
	}

	onSubmit(): void {
		if (!this.password?.value) {
			return;
		}
		const enteredPassword = this._md5?.appendStr(this.password!.value).end()?.toString();
		if (!enteredPassword) {
			return;
		}
		this.isCorrect = Object.keys(this.entryHashes!).includes(enteredPassword);
		if (this.isCorrect) {
			localStorage.setItem('enteredKey', enteredPassword);
			this.router.navigate([this.entryHashes![enteredPassword].redirect]);
		}
	}

	onChange(): void {
		if (this.isCorrect) {
			return;
		}
		this.isCorrect = true;
	}

	onCapsLock(event: boolean): void {
		this.capsOn = event;
	}

	submitSuggestion(key: string): void {
		this._startTyping(key);
	}

	private _startTyping(inputText: string) {
		this.isTyping = true;
		let index = 0;
		this.password.setValue(''); // Clear the field

		const interval = setInterval(() => {
			if (index < inputText.length) {
				this.password.setValue(this.password.value + inputText[index]);
				index++;
			} else {
				clearInterval(interval);
				delay(800); // Re-enable input when typing is done
				this.isTyping = false;
				this.onSubmit();
			}
		}, 100); // Typing speed in milliseconds
	}

	private clearLocalStorage(): void {
		localStorage.clear();
	}
}
