import { Component } from '@angular/core';

@Component({
	selector: 'app-pink-white-page',
	templateUrl: './pink-white-page.component.html',
	styleUrls: ['./pink-white-page.component.scss'],
})
export class PinkWhitePageComponent {
	constructor() {}
}
