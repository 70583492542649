import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, DefaultDataPoint } from 'chart.js';
import { CommonModule } from '@angular/common';
import { BasicPageModule } from '../../modules/basic-page/basic-page.module';
import { BtnModule } from '../../modules/btn/btn.module';
import { BaseChartDirective } from 'ng2-charts';

@Component({
	selector: 'app-decisions-probability',
	templateUrl: './decisions-probability.component.html',
	styleUrls: ['./decisions-probability.component.scss'],
	imports: [CommonModule, BasicPageModule, BtnModule, BaseChartDirective],
	standalone: true,
})
export class DecisionsProbabilityComponent implements OnInit {
	constructor() {
		this.renderChart();
	}

	ngOnInit(): void {}

	readonly _options: ChartConfiguration<'bar', DefaultDataPoint<'bar'>, any>['options'] = {
		animations: {
			tension: {
				duration: 1000,
				easing: 'linear',
				from: 1,
				to: 0,
				loop: true,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false,
				},
			},
			x: {
				grid: {
					display: false,
				},
			},
		},
	};

	get dataset(): ChartData<'bar', DefaultDataPoint<'bar'>, any> | undefined {
		return {
			labels: Array.from({ length: this.n + 1 }, (_, i) => i.toString()), // Labels from 0 to n
			datasets: [
				{
					label: `Binomial Distribution (n=${this.n}, k_min=${this.k_min}, p=${this.p})`,
					data: this.distribution,
					backgroundColor: '#181c1f',
					borderWidth: 0,
				},
			],
		};
	}

	cumulativeProbability: number = 0.0;
	mean: number = 0.0;
	variance: number = 0.0;
	distribution: number[] = [];

	readonly n: number = 365; // Number of trials
	p: number = 0.51; // Probability of success
	readonly k_min: number = 180; // Minimum desired number of outcomes

	setProbability(value: number): void {
		this.p = value;
		this.updateChart();
	}

	private updateChart(): void {
		const { distribution, cumulativeProbability, mean, variance } = this.binomialDistribution();
		this.distribution = distribution;
		this.cumulativeProbability = cumulativeProbability;
		this.mean = mean;
		this.variance = variance;
	}

	private logFactorial(num: number, memo: number[]): number {
		if (memo[num] !== undefined) {
			return memo[num];
		}
		if (num <= 1) {
			return 0; // log(1) = 0
		}
		const result = Math.log(num) + this.logFactorial(num - 1, memo);
		memo[num] = result;
		return result;
	}

	private binomialDistribution(): { distribution: number[]; cumulativeProbability: number; mean: number; variance: number } {
		const distribution = [];
		let cumulativeProbability = 0;
		const logFactorialMemo: number[] = [];

		for (let k = this.k_min; k <= this.n; k++) {
			const logBinomialCoefficient =
				this.logFactorial(this.n, logFactorialMemo) -
				this.logFactorial(k, logFactorialMemo) -
				this.logFactorial(this.n - k, logFactorialMemo);
			const probability = Math.exp(logBinomialCoefficient + k * Math.log(this.p) + (this.n - k) * Math.log(1 - this.p));
			distribution.push(probability);
			cumulativeProbability += probability;
		}

		const mean = Math.round(this.n * this.p);
		const variance = Math.round(this.n * this.p * (1 - this.p));
		return { distribution, cumulativeProbability, mean, variance };
	}

	renderChart(): void {
		const { distribution, cumulativeProbability, mean, variance } = this.binomialDistribution();
		this.cumulativeProbability = cumulativeProbability;
		this.mean = mean;
		this.variance = variance;
		this.distribution = distribution;
	}
}
