import { Component, OnInit } from '@angular/core';
import { UiModule } from '../../modules/ui/ui.module';
import { BtnModule } from '../../modules/btn/btn.module';
import { Router } from '@angular/router';

@Component({
	selector: 'app-about',
	standalone: true,
	imports: [UiModule, BtnModule],
	templateUrl: './about.component.html',
	styleUrl: './about.component.scss',
})
export class AboutComponent implements OnInit {

	constructor(private readonly router: Router) {}

	ngOnInit(): void {}

	onNavigateHome(): void {
		this.router.navigate(['/']);
	}
}
