import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Action, StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore/lite';
import { entryHashesReducer } from './store/private/private.reducers';
import { PrivateEffects } from './store/private/private.effects';
import { SpinnerModule } from './modules/spinner/spinner.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrivatePagesGuardService } from './service/private-pages-guard.service';
import { IEntryHashState } from './store/private/private.model';
import { provideCharts } from 'ng2-charts';
import { BarController, BarElement, CategoryScale, Colors, Legend, LinearScale } from 'chart.js';
import { TennisComponent } from './pages/tennis/tennis.component';
import { YearsPageComponent } from './pages/years-page/years-page.component';
import { WritingsPageComponent } from './pages/writings-page/writings-page.component';
import { UnconventionalComponent } from './pages/unconventional/unconventional.component';
import { BookClippingsComponent } from './pages/book-clippings/book-clippings.component';
import { DecisionsProbabilityComponent } from './pages/decisions-probability/decisions-probability.component';
import { HardworkComponent } from './pages/hardwork/hardwork.component';
import { SufferingComponent } from './pages/suffering/suffering.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { FrankOceanPageComponent } from './pages/frank-ocean-page/frank-ocean-page.component';
import { SoundOnPageComponent } from './pages/sound-on-page/sound-on-page.component';
import { CheapTalkComponent } from './pages/cheap-talk/cheap-talk.component';
import { PrivatePageComponent } from './pages/private-page/private-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';

export const reducers: { entryHash: (state: IEntryHashState | undefined, action: Action) => IEntryHashState } = {
	entryHash: entryHashesReducer,
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HomePageComponent,
		WritingsPageComponent,
		TennisComponent,
		PrivatePageComponent,
		YearsPageComponent,
		CheapTalkComponent,
		SoundOnPageComponent,
		FrankOceanPageComponent,
		NotFoundPageComponent,
		SufferingComponent,
		HardworkComponent,
		UnconventionalComponent,
		BookClippingsComponent,
		DecisionsProbabilityComponent,
		EffectsModule.forRoot([PrivateEffects]),
		StoreModule.forRoot(reducers),
		SpinnerModule,
		BrowserAnimationsModule,
	],
	providers: [
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideFirestore(() => getFirestore()),
		PrivatePagesGuardService,
		provideCharts({ registerables: [BarController, Legend, Colors, LinearScale, CategoryScale, BarElement] }),
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
