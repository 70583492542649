import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AnimationEvent } from '@angular/animations';
import { BtnModule } from '../../modules/btn/btn.module';
import { UiModule } from '../../modules/ui/ui.module';
import { SoundOnModule } from '../../modules/sound-on/sound-on.module';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-years-page',
	templateUrl: './years-page.component.html',
	styleUrls: ['./years-page.component.scss'],
	standalone: true,
	imports: [BtnModule, UiModule, SoundOnModule, CommonModule],
	animations: YearsPageComponent.animationDefinition,
})
export class YearsPageComponent implements OnInit {
	static firstPartTriggerName: string = 'firstPart';
	static secondPartTriggerName: string = 'secondPart';
	firstPart = true;
	secondPart = false;
	playAudio = false;

	constructor() {}

	ngOnInit(): void {
		setTimeout(() => {
			this.playAudio = true;
		}, 800);
	}

	onToggleSections(): void {
		if (this.firstPart) {
			this.firstPart = !this.firstPart;
		} else {
			this.secondPart = !this.secondPart;
		}
	}

	onAnimationEvent(event: AnimationEvent) {
		if (event.triggerName === YearsPageComponent.firstPartTriggerName) {
			if (event.fromState == 'void') {
			} else {
				this.firstPart = false;
				this.secondPart = true;
			}
		} else if (event.triggerName === YearsPageComponent.secondPartTriggerName) {
			if (event.fromState == 'void') {
			} else {
				this.firstPart = true;
				this.secondPart = false;
			}
		}
	}

	static animationDefinition = [
		trigger(YearsPageComponent.firstPartTriggerName, [
			state(
				'in',
				style({
					opacity: 1,
					transform: 'translateY(0)',
				})
			),
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateY(-30%)',
				}),
				animate('0.5s ease'),
			]),
			transition(':leave', [
				animate(
					'0.5s ease',
					style({
						opacity: 0,
						transform: 'translateY(30%)',
					})
				),
			]),
		]),
		trigger(YearsPageComponent.secondPartTriggerName, [
			state(
				'in',
				style({
					opacity: 1,
					transform: 'translateY(0)',
				})
			),
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateY(-30%)',
				}),
				animate('0.5s ease'),
			]),
			transition(':leave', [
				animate(
					'0.5s ease',
					style({
						opacity: 0,
						transform: 'translateY(30%)',
					})
				),
			]),
		]),
	];
}
