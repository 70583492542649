import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../modules/ui/ui.module';
import { BtnModule } from '../../modules/btn/btn.module';

@Component({
	selector: 'app-not-found-page',
	templateUrl: './not-found-page.component.html',
	styleUrls: ['./not-found-page.component.scss'],
	imports: [CommonModule, UiModule, BtnModule],
	standalone: true,
})
export class NotFoundPageComponent implements OnInit {
	constructor(private readonly router: Router) {}

	ngOnInit(): void {}

	onNavigateHome(): void {
		this.router.navigate(['/']);
	}
}
