<app-basic-page>
    <h1 title>talk is <i>not</i> cheap</h1>

    <div content>
        talk is cheap - a phrase usually used to question the viability of the words a person utters
        in contrast to the action they take. Essentially putting more emphasis and bearing on the
        actions they take, rather the words they speak.

        <br/>
        <br/>

        However, I must disagree. This sentence alludes to the idea that people can say things
        without those words bearing any significant meaning. That the words we utter do not
        necessarily influence the judgment of the world on the speaker of said words. I believe this
        is fundamentally incorrect.

        <br/>
        <br/>

        We use language as a way of perceiving the world. Humanity's greatest invention is language
        - one of the many black swans in the history of the human kind. Our brains are modelled
        around the language we speak, and similarly the language we hear. Our perception of reality
        is fundamentally built on top of language and on information that is built on top of
        language. If one were to adhere to this line of thinking, then it would follow that the
        world puts massive emphasis on what is said through language. Significantly more emphasis
        than we, neighbours, friends, and society, hold ourselves accountable to.

        <br/>
        <br/>

        Once we utter a sentence, everything we say and do is contrasted to that and
        creates emphasis on any subsequent action. Rendering the positive, even more positive, and
        by the same token, the negative even more negative. Here's an example:

        <br/>
        <br/>

        A friend says they will always be supportive of you in case of need. There are 2 ways this
        sentence can go in case of need:

        <li>
            your friend does in fact support you when you're in need.
        </li>
        <li>
            your friend does not support you when you're in need.
        </li>


        In both cases, the perception of your friend is changed not only by the action they have
        taken, but by the initial context they have created based on the words they have spoken.

        <br/>
        <br/>

        Had the friend not spoken the initial statement, then whether they have helped you or not
        would not have any bearing on your perception of them. You could simply be happy that they
        have supported you, or be indifferent to the fact that they haven't - as long as you don't
        expect them to do so anyway.

        <br/>
        <br/>

        Therefore, the words we speak are inherently not cheap at all. Matter of fact, one should
        likely weigh what they say significantly more cautiously than what they do. Considering that
        having said something prior to taking an action can set context as to whether that action is
        negative or positive. Practically, we must understand that any manifestation of intent
        (action, etc...) can be greatly emphasised by the context we have created through our
        words.

        <br/>
        <br/>

        <i>
            Speak honestly, or be quiet. Talk is not cheap.
        </i>
    </div>

    <img class="cheap-talk__image" image src="assets/images/espresso-martini.jpg" alt="wooden table, espresso martinis, and some cake from summer '23"
         fill/>
    <figcaption caption>espresso martini's & cake, june '23</figcaption>
</app-basic-page>
