<div appTypography textStyle="header-2" class="years-page__header">2 years.</div>

<div class="years-page__content">
	<div class="years_page_blog-area">
		<div appTypography textStyle="body-small" class="years-page__text">
			<div *ngIf="firstPart" @firstPart (@firstPart.done)="onAnimationEvent($event)">
				The past two years have been the most pivotal in my life. Though they mainly constituted me finding someone I already was.
				<br /><br />
				I started 2020 in a strange place. I had nothing but a gut feeling and a breath of hope pushing me to redefine who I had become. All
				I knew was that I had to do better and that I could. In everything. Having then realized that it would be impossible for me to find
				who I truly was amidst all the noise I was surrounded with. I made the conscious and, at the time, difficult decision to retreat
				from the world. Or at least, the parts I believed were not fundamental to my Being.
				<br /><br />
				It was a rather imprecise cut, basing the boundary of what and could not stay merely on an instinctual inner feeling. I kept a
				handful of people close to me and lengthened the distance I had with others. I removed most unneeded distractions. I buried myself
				in work and surrendered it to the process of rediscovering which elements were fundamental to my inner-self. It was predominantly
				based on trial and error. I carried things out, then saw how I reacted. It was unhurried and incredibly complex. Yet, it worked.
				<br /><br />
				For a year and a half, I have precluded most outward expression toward the world. However, I was there for myself for the first time
				in forever and a day. I was guiding, learning, and attending to myself. I thought myself things. I was frequently hard on myself but
				also encouraging when need be. I made many mistakes but enabled myself to correct them. I was there, building something for which my
				future self would thank me.
			</div>
			<div *ngIf="secondPart" @secondPart (@secondPart.done)="onAnimationEvent($event)">
				It was long, tedious, and often entertaining. I lost many things on this chapter of the journey; however, I gained immensely more.
				Then with this newfound soundness, I was ready to scour more. See what's out there. It was a controlled transition out of my
				rigorously shielded reality. Then it hit me. This order I had built, or antidote to chaos, was heavily reliant on this protected
				reality. It was challenging for it to survive in an alien world. I was bewildered and doubtful. I had, suddenly, lost all the
				direction I had before. I had reached a point where I stood stale and airless. Where the ceiling of what's next was too close. I had
				to understand what was next. Where I was headed. I didn't feel right in the environment where I was. So I moved back somewhere else.
				Home. I had to take a step back to that 3 forward. It was a tricky decision to make. But it's been worthwhile.
				<br /><br />
				In one way or another. This piece encompasses this everlasting journey. And this is a step. This is a moment where I wholeheartedly
				recognize the efforts and the process.
				<br /><br />
				And doing so in a way showing gratitude to everyone who had contributed to who I had become. Gratitude for the privilege, support,
				and conditions that are so rarely found in life.
				<br /><br />
				It's strange because this feels like breaking out of character, but it's not. This is also me. Just a part that rarely likes to be
				noticed 😇.
			</div>
		</div>
		<div class="years-page__image-section">
			<div class="years-page__image">
				<div class="years-page__first-image">
					<img src="/assets/images/img1.png" alt="living-room"/>
					<div appTypography textStyle="body-small">
						Summer, <br />
						Hamburg, '21
					</div>
				</div>
				<div class="years-page__second-image" *ngIf="secondPart" @secondPart>
					<img src="/assets/images/img2.png" alt="desk bremen"/>
					<div appTypography textStyle="body-small">
						Summer, <br />
						Bremen, '21
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="years-page__date-block">
		<svg width="29" height="847" viewBox="0 0 29 847" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M14.9849 28L0.984865 14L14.9849 -1.22392e-06L28.9849 14L14.9849 28Z" fill="#191919" />
			<path d="M14.9849 847L14.9849 27" stroke="black" />
		</svg>
		<div appTypography textStyle="body-small" class="years-page__date">15 May 2022</div>
	</div>
</div>

<button appBtn buttonType="icon" (click)="onToggleSections()">
	<i [ngClass]="firstPart ? 'mi-arrow-down' : 'mi-arrow-up'"></i>
</button>

<iframe src="assets/sound/moon-river.mp3" allow="autoplay" id="audio" *ngIf="playAudio"></iframe>
