import { Component } from '@angular/core';
import { BasicPageModule } from '../../modules/basic-page/basic-page.module';

@Component({
	selector: 'app-tennis',
	standalone: true,
	imports: [BasicPageModule],
	templateUrl: './tennis.component.html',
	styleUrl: './tennis.component.scss',
})
export class TennisComponent {}
