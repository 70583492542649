import { AfterContentInit, Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
	selector: 'app-lyric-band',
	templateUrl: './lyric-band.component.html',
	styleUrls: ['./lyric-band.component.scss'],
	animations: [
		trigger('showBand', [
			state(
				'true',
				style({
					opacity: 1,
				})
			),
			state(
				'false',
				style({
					opacity: 0,
				})
			),
			transition('false => true', [animate('0.7s ease-in')]),
			transition('true => false', [animate('0.7s ease-out')]),
		]),
	],
})
export class LyricBandComponent implements AfterContentInit {
	constructor() {}

	enabled: boolean = false;

	ngAfterContentInit(): void {
		delay(2000);
		this.enabled = true;
	}
}
