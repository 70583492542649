<h1 appTypography textStyle="header-2">Root</h1>

<div appTypography textStyle="body-small" class="home-page__table">
	<table>
		<tr>
			<th class="home-page__table__entry">entry</th>
			<th class="home-page__table__description">description</th>
			<th class="home-page__table__created">published</th>
		</tr>

		<tr *ngFor="let entry of entryHashes$ | async">
			<td class="home-page__table__link">
				<a [routerLink]="'/' + entry.redirect">
					{{ entry.title }}
				</a>
			</td>
			<td>
				<a> {{ entry.description }} </a>
			</td>
			<td>
				<a>{{ entry.createdDate.toDate() | date }} </a>
			</td>
		</tr>
	</table>
</div>
