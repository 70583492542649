<div class="horizontal-titles">
	<div appTypography textStyle="huge" class="first-title">discovering</div>

	<div appTypography textStyle="huge" class="second-title">the</div>
</div>

<div appTypography textStyle="huge" class="third-title">sound.</div>

<button appBtn buttonType="icon" class="next-button" (click)="onNext()">
	<i class="mi-chevron-right"></i>
</button>
