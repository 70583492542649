<app-basic-page>
    <h1 title>minute choices and the tapestry that is your life</h1>

    <div content>


        Assume your objective for this year is to save $1 every other day. This means you have 365
        days (in a non-leap year) to save $180. Let's consider a few different scenarios, broken
        down by the likelihood of you saving the dollar on any given day.

        <br/>
        <br/>

        First, assume for any given day, you have a 49%
        <button class="decisions-probability__preview" appBtn buttonType="icon"
                (click)="setProbability(0.49)">
            <i class="mi-eye"></i>
        </button>
        chance of saving $1. The probability of
        hitting your goal by the end of the year is approximately 47%. In other words, you will
        likely NOT hit your goal.

        <br/>
        <br/>

        Now, let's make a couple of adjustments. Suppose you start saying no to those tempting
        snacks at the supermarket. By addressing this habit, you increase your probability of saving
        on any given day to 51%
        <button class="decisions-probability__preview" appBtn buttonType="icon"
                (click)="setProbability(0.51)">
            <i class="mi-eye"></i>
        </button>
        . This 2% increase results in a probability of around 76% that you'll
        hit your goal by the end of the year. That's a 29% increase in your chances for just a
        slight change.

        <br/>
        <br/>

        Next, imagine you cancel that one subscription you never needed, saving a bit more money.
        This action increases your probability by another 1 percentage point, so you now have a 52%
        <button class="decisions-probability__preview" appBtn buttonType="icon"
                (click)="setProbability(0.52)">
            <i class="mi-eye"></i>
        </button>
        chance of saving each day. Consequently, your probability of reaching your goal rises to
        about 86%—a 10% increase from the previous scenario.

        <br/>
        <br/>

        Finally, suppose you also decide to forgo those comfy Uber rides and food deliveries. This
        adjustment changes your likelihood of saving each day to 56%
        <button class="decisions-probability__preview" appBtn buttonType="icon"
                (click)="setProbability(0.56)">
            <i class="mi-eye"></i>
        </button>
        . With this improvement, you're
        now 99.5% likely to hit your goal by the end of the year. In fact, you might even exceed
        your goal and save $200!

        <br/>
        <br/>

        Consider that you might get exceptionally good at these habits, increasing your likelihood
        of making the right decision to 80%
        <button class="decisions-probability__preview" appBtn buttonType="icon"
                (click)="setProbability(0.80)">
            <i class="mi-eye"></i>
        </button>
        . At that point, you might consider raising your goal to
        $300 and pushing yourself to make even better decisions each day.

        <br/>
        <br/>

        In the end, you don't need to make the right decision every single day. You only need to
        make it 56% of the time to win—a small tilt towards a favorable outcome each day compounds
        into massive wins over time.

        <br/>
        <br/>

        I generally believe this principle applies when you look at the grander scheme of things,
        regardless of how you frame your objectives and it applies in two main ways.

        <br/>
        <br/>

        Primo, you don't have to be perfect and you don't have to get it right every
        single day. You only need to get it right more often than not. This is a powerful concept to
        contemplate.

        <br/>
        <br/>

        Secundus omnium, you can make changes that might seem very small in the grander scheme of
        things. These small changes can quickly compound over time and drive better
        outcomes. However, this also goes the other way around. Sometimes a small habit or decision
        that you
        fundamentally know is bad but doesn't seem to matter on a bigger scale can compound over
        time and drive you to a worse outcome.

        <br/>
        <br/>

        I believe that one of the hidden risks in decision making lies in the fact that we tend to perceive the world in the
        following way: at a small enough scale everything matters, at a big enough scale nothing really
        matter (more on this another day).

        <br/>
        <br/>

        What separates us from a better future is much smaller than we often think.

    </div>
    <div image class="chart-container">
        <canvas baseChart [options]="_options" [data]="dataset" type="bar"></canvas>
    </div>
    <figcaption caption>
        <div>
            likelihood of success:
            {{ cumulativeProbability | percent: '1.0-2' }}
        </div>
        <div>
            mean $ saved (rounded): {{ mean | number: '1.0-0' }}
        </div>
        <div>
            variance (rounded): {{ variance | number: '1.0-0' }}
        </div>
    </figcaption>

</app-basic-page>
