<div class="title" appTypography textStyle="header-2">
	<ng-content select="[title]"></ng-content>
</div>
<div [ngClass]="{ mobile: isMobile$ | async }">
	<div class="basic-page__body">
		<div class="basic-page__body__text" appTypography textStyle="body-small">
			<ng-content select="[content]"></ng-content>
		</div>

		<div>
			<ng-content select="[image]"></ng-content>
			<span appTypography textStyle="body-small">
				<ng-content select="[caption]"></ng-content>
			</span>
		</div>
	</div>
</div>
