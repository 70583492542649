import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-solo-card',
	templateUrl: './solo-card.component.html',
	styleUrls: ['./solo-card.component.scss'],
})
export class SoloCardComponent {
	constructor() {}
}
