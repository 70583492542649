import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-frank-ocean-titles',
	templateUrl: './frank-ocean-titles.component.html',
	styleUrls: ['./frank-ocean-titles.component.scss'],
})
export class FrankOceanTitlesComponent {
	@Output() next: EventEmitter<void> = new EventEmitter<void>();

	constructor() {}

	onNext(): void {
		this.next.emit();
	}
}
