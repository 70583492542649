import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-basic-page',
	templateUrl: './basic-page.component.html',
	styleUrls: ['./basic-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicPageComponent implements OnInit {
	isMobile$: Observable<boolean> | undefined;
	constructor(private readonly breakpointObserver: BreakpointObserver) {}

	ngOnInit(): void {
		this.isMobile$ = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(map((bpState: BreakpointState) => bpState.matches));
	}
}
