import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../modules/ui/ui.module';
import { BasicPageModule } from '../../modules/basic-page/basic-page.module';

@Component({
	selector: 'app-hardwork',
	templateUrl: './hardwork.component.html',
	styleUrls: ['./hardwork.component.scss'],
	imports: [CommonModule, UiModule, BasicPageModule],
	standalone: true,
})
export class HardworkComponent {
	constructor() {}
}
