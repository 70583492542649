import { Component, Input } from '@angular/core';
import { IClipping } from '../data/clipping.model';

@Component({
	selector: 'app-clipping',
	templateUrl: './clipping.component.html',
	styleUrls: ['./clipping.component.scss'],
})
export class ClippingComponent {
	@Input() clipping?: IClipping;
}
