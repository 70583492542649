import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClippingComponent } from './component/clipping.component';
import { UiModule } from '../ui/ui.module';

@NgModule({
	declarations: [ClippingComponent],
	imports: [CommonModule, UiModule],
	exports: [ClippingComponent],
})
export class ClippingModule {}
