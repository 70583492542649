import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../modules/ui/ui.module';
import { BasicPageModule } from '../../modules/basic-page/basic-page.module';

@Component({
	selector: 'app-suffering',
	templateUrl: './suffering.component.html',
	styleUrls: ['./suffering.component.scss'],
	imports: [CommonModule, UiModule, BasicPageModule],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SufferingComponent {}
