import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrankOceanTitlesComponent } from './frank-ocean-titles/frank-ocean-titles.component';
import { UiModule } from '../ui/ui.module';
import { BtnModule } from '../btn/btn.module';
import { BlondeCardComponent } from './blonde-card/blonde-card.component';
import { SoloCardComponent } from './solo-card/solo-card.component';
import { PinkWhiteCardComponent } from './pink-white/pink-white-card/pink-white-card.component';
import { PinkWhitePageComponent } from './pink-white/pink-white-page/pink-white-page.component';
import { LyricBandComponent } from './lyric-band/lyric-band.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
	declarations: [
		FrankOceanTitlesComponent,
		BlondeCardComponent,
		SoloCardComponent,
		PinkWhiteCardComponent,
		PinkWhitePageComponent,
		LyricBandComponent,
	],
	exports: [FrankOceanTitlesComponent, BlondeCardComponent, PinkWhiteCardComponent, SoloCardComponent, PinkWhitePageComponent],
	imports: [CommonModule, UiModule, BtnModule, BrowserAnimationsModule, BrowserModule],
})
export class FrankOceanModule {}
