import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicPageComponent } from './basic-page.component';
import { UiModule } from '../ui/ui.module';

@NgModule({
	declarations: [BasicPageComponent],
	imports: [CommonModule, UiModule],
	exports: [BasicPageComponent],
})
export class BasicPageModule {}
