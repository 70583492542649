import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as fromPrivate from '../../store/private';
import { Store } from '@ngrx/store';
import { IEntryHash, IEntryHashState } from '../../store/private/private.model';
import { IAppState } from '../../data/general.data';
import { SpinnerService } from '../../modules/spinner/service/spinner.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../modules/ui/ui.module';
import { RouterModule } from '@angular/router';
import { BtnModule } from '../../modules/btn/btn.module';

@Component({
	selector: 'app-writings-page',
	templateUrl: './writings-page.component.html',
	styleUrls: ['./writings-page.component.scss'],
	imports: [CommonModule, UiModule, RouterModule, BtnModule],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WritingsPageComponent implements OnInit {
	entryHashes$: Observable<IEntryHash[]> | undefined;

	constructor(private readonly store: Store<IAppState>, private readonly spinnerService: SpinnerService) {
		this.store
			.select(fromPrivate.selectEntryHashState)
			.pipe()
			.subscribe((entryHashState: IEntryHashState) => {
				if (entryHashState.isLoading) {
					this.spinnerService.show();
				}
				if (entryHashState.isLoaded) {
					this.spinnerService.hide();
				}
			});
	}

	ngOnInit(): void {
		this.entryHashes$ = this.store.select(fromPrivate.selectPublicEntryHashes);
	}
}
