import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicPageModule } from '../../modules/basic-page/basic-page.module';

@Component({
	selector: 'app-unconventional',
	templateUrl: './unconventional.component.html',
	styleUrls: ['./unconventional.component.scss'],
	standalone: true,
	imports: [CommonModule, BasicPageModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnconventionalComponent {}
