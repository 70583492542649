import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrankOceanModule } from '../../modules/frank-ocean/frank-ocean.module';
import { UiModule } from '../../modules/ui/ui.module';

@Component({
	selector: 'app-frank-ocean-page',
	templateUrl: './frank-ocean-page.component.html',
	styleUrls: ['./frank-ocean-page.component.scss'],
	imports: [CommonModule, FrankOceanModule, UiModule],
	standalone: true,
})
export class FrankOceanPageComponent {
	constructor() {}

	activeStep: 'first' | 'second' | 'third' = 'first';
	activeSong: 'one' | 'two' | 'three' | null = null;

	onFirstStepNext(): void {
		this.activeStep = 'second';
	}

	onSelectSong(song: 'one' | 'two' | 'three'): void {
		this.activeSong = song;
	}
}
