<app-basic-page>
    <h1 title>the virtue of hard work</h1>

    <div content>
        I don't like ambition. I don't believe in ambition. I don't believe in things that don't
        feel real. Overused narratives that don't
        really imply anything about the person or the character in question. I believe in hard work,
        hard work is tangible, hard work is sensed,
        it's seen, it's felt, and it's measured. Ambition, motivation, drive, dreaming, all these
        things don't mean anything to me unless one
        can translate them into hard work. Therefore, I believe the only true manifestation of
        anyone's features and character traits can only
        be expressed through tangible by-products of those features, in this case hard work:

        <br/><br/>

        <ul>
            <li>If you work hard, you're ambitious.</li>
            <li>If you work hard, you're motivated.</li>
            <li>If you work hard, you're driven.</li>
            <li>If you work hard, you fail.</li>
            <li>If you work hard, you learn.</li>
            <li>If you work hard, you succeed.</li>
            <li>If you work hard, you grow.</li>
        </ul>

        The list can go one forever... So why overindulge over other less-significant traits if the
        parent of them all can speak volumes more?. Let your actions speak for you. Express your
        character traits through other tangible and
        measurable traits. Don't fall for the narrative. Rise above.
    </div>

    <img class="hardwork__image" image src="assets/images/working-hands.jpeg"/>
    <figcaption caption>working hands drawing - Ja'Quay Jones</figcaption>
</app-basic-page>
