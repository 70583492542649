import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	animations: [
		trigger('showLyricBands', [
			state('true', style({ 'background-color': 'blue' })),
			state('false', style({ 'background-color': 'red' })),
			transition('false => true', animate('0.7s ease')),
		]),
	],
	selector: 'app-pink-white-card',
	templateUrl: './pink-white-card.component.html',
	styleUrls: ['./pink-white-card.component.scss'],
})
export class PinkWhiteCardComponent {
	@Output() press: EventEmitter<'one'> = new EventEmitter();

	active: boolean = false;

	onClick(): void {
		this.press.emit('one');
		this.active = true;
	}
}
