<app-basic-page>
    <h1 title>embracing the unconventional path</h1>

    <div content>
        Unconventional decisions are something that has helped me reshape the person I am today.
        They've allowed me to make drastic changes in
        my life and my character. I made the changes that had to happen when it was the right time
        for them to happen. One thing I realized when
        making these radical decisions is that people generally don't agree with them. They stir up
        a lot of confusion because they do not lie
        along the path of usual decision-making. However, until now, all these decisions have become
        accepted, and most of them even adopted by
        the same people who disagreed with them in the beginning.

        <br/><br/>

        Does that make them always right? No. Does that make them legitimate? No. But what it does
        is it shows you that people can't be trusted
        with their judgment. People are afraid to make a change, and even more so when it's radical.
        People are afraid to try things that are
        outside of their subjective life experience, so they'll always deem it insane to make such a change. That
        doesn't make it false.

        <br/><br/>

        Don't get me wrong, even a radical and unconventional change must be very well thought out.
        It must be understood and analyzed. A big
        change will have a big impact, so you must have some degree of certainty that it will pay
        off the way you want it to.

        <br/><br/>

        It has worked. It is working. It will work, regardless of how difficult things might seem.
        There is always a chance to undo things. The
        earlier you start, the easier it is to undo. Don't settle for mediocrity because it's hard
        to change. Push. Trust in yourself and
        believe in yourself. Challenge yourself. People will always be doubtful, not because they
        don't like you, but because they do. You must trust yourself. You must believe in yourself.
        Only you know your current set of circumstances. Only you understand all
        the variables.

        <br/><br/>

        Do now. Do strong.
    </div>

    <img class="unconventional__image" image src="assets/images/sunset.jpeg"/>
    <figcaption caption>a glary agadir sunset, february '23</figcaption>
</app-basic-page>
